import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField, GroupedField } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, getParentPropertyPath } from '@ngt/forms';
import { NewLesion, OverallResponse, TargetMeasurableLesion, NonMeasurableLesion, ParticipantStudySummary as TumourAssessmentsDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from '@mui/styles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import ValueWithSpecificationDisplay from '../../components/form/input/display/ValueWithSpecificationDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { get } from 'lodash';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface ITumourAssessmentsProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const hasTargetLesionResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {}, parentFieldName }) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: TargetMeasurableLesion = get(value, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.lesion1Measurement != null && row.timepoint != 'Baseline';
};

const hasNonTargetLesionResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {}, parentFieldName }) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: NonMeasurableLesion = get(value, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.lesion1Status != null && row.timepoint != 'Baseline';
};

const targetMeasurableLesionColumns: IGroupedSubformGridCell<TargetMeasurableLesion>[] = [
    {
        name: 'timepoint',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.timepoint,
        width: 200
    },
    {
        name: 'notDoneReason',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="notDoneReasonSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.notDoneReason,
        width: 100
    },
    {
        name: 'lesion1Measurement',
        content: (
            <Input
                component={NumericDisplay}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
            />
        ),
        sortValue: (row) => row?.lesion1Measurement
    },
    {
        name: 'lesion2Measurement',
        content: (
            <Input
                component={NumericDisplay}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
            />
        ),
        sortValue: (row) => row?.lesion2Measurement
    },
    {
        name: 'lesion3Measurement',
        content: (
            <Input
                component={NumericDisplay}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
            />
        ),
        sortValue: (row) => row?.lesion3Measurement
    },
    {
        name: 'lesion4Measurement',
        content: (
            <Input
                component={NumericDisplay}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
            />
        ),
        sortValue: (row) => row?.lesion4Measurement
    },
    {
        name: 'lesion5Measurement',
        content: (
            <Input
                component={NumericDisplay}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
            />
        ),
        sortValue: (row) => row?.lesion5Measurement
    },
    {
        name: 'sumOfAllTargetLesions',
        content: (
            <Input
                component={NumericDisplay}
                suffix={<Typography color="textSecondary" variant="caption" component="span"> mm</Typography>}
            />
        ),
        sortValue: (row) => row?.sumOfAllTargetLesions
    },
    {
        name: 'targetLesionResponse',
        content: (
            <Condition
                type={TumourAssessmentsDto}
                condition={hasTargetLesionResponse}
                subscription={conditionSubscription}
                mode={ConditionMode.Show}
            >
                <Input
                    component={IfElseDisplay}
                    ifName="targetLesionResponse"
                    elseName="targetLesionResponse"
                    elseAccessor={(value: string) => "Missing"}
                    condition={(value: string) => (value?.length ?? 0) > 0}
                    ifPrependParentName={true}
                    ifTableCell={true}
                />
            </Condition>
        ),
        sortValue: (row) => row?.targetLesionResponse
    }
];

const nonMeasurableLesionColumns: IGroupedSubformGridCell<NonMeasurableLesion>[] = [
    {
        name: 'timepoint',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.timepoint,
        width: 200
    },
    {
        name: 'notDoneReason',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="notDoneReasonSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.notDoneReason,
        width: 100
    },
    {
        name: 'lesion1Status',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.lesion1Status
    },
    {
        name: 'lesion2Status',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.lesion2Status
    },
    {
        name: 'lesion3Status',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.lesion3Status
    },
    {
        name: 'lesion4Status',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.lesion4Status
    },
    {
        name: 'lesion5Status',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.lesion5Status
    },
    {
        name: 'nonTargetLesionResponse',
        content: (
            <Condition
                type={TumourAssessmentsDto}
                condition={hasNonTargetLesionResponse}
                subscription={conditionSubscription}
                mode={ConditionMode.Show}
            >
                <Input
                    component={IfElseDisplay}
                    ifName="nonTargetLesionResponse"
                    elseName="nonTargetLesionResponse"
                    elseAccessor={(value: string) => "Missing"}
                    condition={(value: string) => (value?.length ?? 0) > 0}
                    ifPrependParentName={true}
                    ifTableCell={true}
                />
            </Condition>
        ),
        sortValue: (row) => row?.nonTargetLesionResponse
    }
];

const newLesionColumns: IGroupedSubformGridCell<NewLesion>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'lesionNumber',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.lesionNumber
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.date   
    },
    {
        name: 'methodOfMeasurement',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="methodOfMeasurementSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.methodOfMeasurement
    },
    {
        name: 'site',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="siteSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'locationWithinOrgan',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.locationWithinOrgan
    }
];

const overallResponseColumns: IGroupedSubformGridCell<OverallResponse>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'response',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.response
    }
];

const hasNoTargetMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.targetMeasurableLesions || value?.targetMeasurableLesions?.length === 0;
const hasTargetMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.targetMeasurableLesions && value?.targetMeasurableLesions?.length > 0;

const hasNoNonMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.nonMeasurableLesions || value?.nonMeasurableLesions?.length === 0;
const hasNonMeasurableLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.nonMeasurableLesions && value?.nonMeasurableLesions?.length > 0;

const hasNoNewLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.newLesions || value?.newLesions?.length === 0;
const hasNewLesions: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.newLesions && value?.newLesions?.length > 0;

const hasNoOverallResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !value?.overallResponses || value?.overallResponses?.length === 0;
const hasOverallResponse: ICondition<TumourAssessmentsDto> = ({ formState: { value } = {} }) => !!value?.overallResponses && value?.overallResponses?.length > 0;

const TumourAssessments: FunctionComponent<ITumourAssessmentsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Tumour Assessments
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                <GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Target Measurable Lesions
                    </Typography>
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasTargetMeasurableLesions}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box
                            sx={{
                                py: 2
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                width: '23%'
                                            }}
                                        >
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 1
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 2
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 3
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 4
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 5
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                            <Typography variant='h5'>
                                                Site
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion1Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="targetMeasurableLesion1SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion2Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="targetMeasurableLesion2SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion3Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="targetMeasurableLesion3SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion4Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="targetMeasurableLesion4SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion5Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="targetMeasurableLesion5SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                            <Typography variant='h5'>
                                                Location in organ
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion1OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion2OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion3OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion4OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="targetMeasurableLesion5OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                        <GroupedSubformGrid
                            type={TargetMeasurableLesion}
                            name="targetMeasurableLesions"
                            columns={targetMeasurableLesionColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasNoTargetMeasurableLesions}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Target Measurable Lesions"
                                description="There is no target measurable lesion associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Non-Measurable Lesions
                    </Typography>
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasNonMeasurableLesions}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box
                            sx={{
                                py: 2
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                width: '23%'
                                            }}
                                        >
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 1
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 2
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 3
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 4
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '11.6%'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                LESION 5
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                            <Typography variant='h5'>
                                                Site
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion1Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="nonMeasurableLesion1SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion2Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="nonMeasurableLesion2SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion3Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="nonMeasurableLesion3SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion4Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="nonMeasurableLesion4SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion5Site"
                                                component={ValueWithSpecificationDisplay}
                                                specificationName="nonMeasurableLesion5SiteSpec"
                                            />
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'right', paddingRight: '2.5rem' }}>
                                            <Typography variant='h5'>
                                                Location in organ
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion1OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion2OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion3OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion4OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                wordBreak: 'break-word',
                                                overflowWrap: 'anywhere'
                                            }}
                                        >
                                            <InputOnlyField
                                                name="nonMeasurableLesion5OrganLocation"
                                                component={TextDisplay}
                                            />
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                        <GroupedSubformGrid
                            type={NonMeasurableLesion}
                            name="nonMeasurableLesions"
                            columns={nonMeasurableLesionColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasNoNonMeasurableLesions}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Non-Measurable Lesions"
                                description="There is no non-measurable lesion associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        New Lesions
                    </Typography>

                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasNewLesions}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={NewLesion}
                            name="newLesions"
                            columns={newLesionColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasNoNewLesions}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No New Lesions"
                                description="There is no new lesion associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Overall Response
                    </Typography>

                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasOverallResponse}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={OverallResponse}
                            name="overallResponses"
                            columns={overallResponseColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TumourAssessmentsDto}
                        condition={hasNoOverallResponse}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Overall Responses"
                                description="There is no overall response associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default TumourAssessments;
