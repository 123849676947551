import { Accordion, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { DateDisplay, FieldProvider, FormLabel, GroupedFields, IGroupedFieldStyleProps, TextDisplay } from '@ngt/forms';
import { ContextFormTitle, ContextPatientInformation, ContextPISignOffForm, ContextPISignOffFormBreadcrumbs, ContextFormQueryGrid, InputOnlyField, GroupedField, ContextFormDataDate } from '@ngt/forms-pi-signoff';
import React, { FunctionComponent, useContext } from 'react';
import AdverseEvents from './AdverseEvents';
import BiopsyReports from './BiopsyReports';
import Death from './Death';
import DiamondTreatment from './DiamondTreatment';
import ConcomitantMedications from './ConcomitantMedications';
import OrganFunction from './OrganFunction';
import Progression from './Progression';
import TumourAssessments from './TumourAssessments';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { IPISignOffExtensionOptions } from '@ngt/forms-pi-signoff/dist/PISignOffExtension';
import { PISignOffExtensionContext } from '@ngt/forms-pi-signoff';
import PreviousBreastCancerTreatment from './PreviousBreastCancerTreatment';
import FollowUp from './FollowUp';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface IParticipantStudySummaryProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 0, 2)
    },
    subtitle: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
}));

const ParticipantStudySummary: FunctionComponent<IParticipantStudySummaryProps> = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const piSignOffExtensionContext: IPISignOffExtensionOptions = useContext(PISignOffExtensionContext);

    return (
        <>
            <ContextPISignOffFormBreadcrumbs />
            <div className={classes.patientInformation}>
            <ContextPatientInformation />
            </div>
            <Typography variant="h1" className={classes.title}>
                <ContextFormTitle />
            </Typography>
            <br />

            <div className="instructions">
                <Typography variant="subtitle1" className={classes.subtitle}>Dear Investigator,</Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>'Participant Study Summary' below provides a consolidated summary of key data points for the selected DIAmOND study participant, as entered into the OpenClinica eCRF. </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>To review the eCRF Casebook in its entirety, please click the 'eCRF Casebook' button. This will generate the current data in the eCRF for each individual data point. The eCRF Casebook can be accessed at any timepoint. </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>Please acknowledge your review of the eCRF data Casebook and below summary via the 'Approve' or 'Request Data Change' buttons at the bottom end of this page.</Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>Please note that marking this data as 'Approved' you are confirming that the data entered in the eCRF are attributable, legible, contemporaneous, original, accurate and complete. </Typography>
            </div>

            <Typography variant="subtitle1" className={classes.subtitle}>
                <ContextFormDataDate />
            </Typography>
            <ContextPISignOffForm
                queriesName='queries'
            >
                <PreviousBreastCancerTreatment />
                <DiamondTreatment />
                <OrganFunction />
                <AdverseEvents />
                <ConcomitantMedications />
                <BiopsyReports />
                <TumourAssessments />
                <Progression />
                <FollowUp />
                <Death />
                <ContextFormQueryGrid name="queries" />
            </ContextPISignOffForm>
        </>
    );
};

export default ParticipantStudySummary;
