import { Accordion, AccordionDetails, AccordionSummary, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Condition, GroupedField, ICondition } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, NumericDisplay, } from '@ngt/forms';
import { ConditionMode } from '@ngt/forms-core';
import { makeStyles } from '@mui/styles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ParticipantStudySummary as PreviousBreastCancerTreatmentDto } from '../../apis/dtos';

interface IPreviousBreastCancerTreatmentProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    accordionSubtitle: {
        color: 'white'
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const PreviousBreastCancerTreatment: FunctionComponent<IPreviousBreastCancerTreatmentProps> = () => {
    const classes = useStyles();

    const hasNoPriorLines: ICondition<PreviousBreastCancerTreatmentDto> = ({ formState: { value } = {} }) => value?.priorLinesOfTherapy == null;
    const hasPriorLines: ICondition<PreviousBreastCancerTreatmentDto> = ({ formState: { value } = {} }) => value?.priorLinesOfTherapy != null;

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Previous Breast Cancer Treatment
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields>
                        <Condition
                            type={PreviousBreastCancerTreatmentDto}
                            condition={hasNoPriorLines}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="priorLinesOfTherapy"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                                convert={(value) => "Not Available"}
                            />
                        </Condition>
                        <Condition
                            type={PreviousBreastCancerTreatmentDto}
                            condition={hasPriorLines}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="priorLinesOfTherapy"
                                component={NumericDisplay}
                                GroupStyleProps={groupStyleProps}
                                suffix="Lines(s)"
                            />
                        </Condition>
                    </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default PreviousBreastCancerTreatment;
