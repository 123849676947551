/* Options:
Date: 2024-03-28 10:49:29
Version: 6.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44823

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export class NativeTypesBase
{
    public baseUrl: string;
    public makePartial?: boolean;
    public makeVirtual?: boolean;
    public makeInternal?: boolean;
    public addReturnMarker?: boolean;
    public addDescriptionAsComments?: boolean;
    public addDocAnnotations?: boolean;
    public addDataContractAttributes?: boolean;
    public makeDataContractsExtensible?: boolean;
    public addIndexesToDataMembers?: boolean;
    public addGeneratedCodeAttributes?: boolean;
    public initializeCollections?: boolean;
    public addImplicitVersion?: number;
    public addResponseStatus?: boolean;
    public addServiceStackTypes?: boolean;
    public addModelExtensions?: boolean;
    public addPropertyAccessors?: boolean;
    public excludeGenericBaseTypes?: boolean;
    public settersReturnThis?: boolean;
    public addNullableAnnotations?: boolean;
    public makePropertiesOptional?: boolean;
    public exportAsTypes?: boolean;
    public exportValueTypes?: boolean;
    public excludeNamespace?: boolean;
    public addDefaultXmlNamespace: string;
    public globalNamespace: string;
    public baseClass: string;
    public package: string;
    public dataClass: string;
    public dataClassJson: string;
    public addNamespaces: string[];
    public defaultNamespaces: string[];
    public defaultImports: string[];
    public includeTypes: string[];
    public excludeTypes: string[];
    public exportTags: string[];
    public treatTypesAsStrings: string[];

    public constructor(init?: Partial<NativeTypesBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class PISignOffBatchCreationOptions
{
    public formDefinitionIds: number[];
    public completeUnchangedForms?: boolean;
    public compareFormsAcrossPISignOffDefinitions?: boolean;

    public constructor(init?: Partial<PISignOffBatchCreationOptions>) { (Object as any).assign(this, init); }
}

export interface IUserGetSingle
{
    metadata: { [index: string]: Object; };
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum PISignOffStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
    Pending = 5,
    Failed = 6,
}

export class AdverseEvent implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public systemsOrganClass: string;
    public aeTermId?: number;
    public aeTerm: string;
    public aeTermSpecify: string;
    public grade?: number;
    public onset?: string;
    public outcome: string;
    public resolutionDate?: string;
    public aeRelationship: string;
    public actionTaken: string;
    public qualifyAsAnAesi: string;
    public meetSaeCriteria: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvent>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByPISignOffIdAndRepeat extends IGet
{
    piSignOffId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class AntiHer2Therapy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public type: string;
    public typeSpec: string;
    public use: string;
    public current: string;
    public dateOfCommencement: string;
    public dateOfCompletion: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AntiHer2Therapy>) { (Object as any).assign(this, init); }
}

export class AromataseTreatment implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public eventName: string;
    public name: string;
    public nameSpecify: string;
    public dailyDose?: number;
    public startDate?: string;
    public stopDate?: string;
    public reason: string;
    public reasonSpec: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AromataseTreatment>) { (Object as any).assign(this, init); }
}

export class Biochemistry implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public urea?: number;
    public ureaNotDone?: boolean;
    public ureaLln?: number;
    public ureaUln?: number;
    public creatinine?: number;
    public creatinineNotDone?: boolean;
    public creatinineLln?: number;
    public creatinineUln?: number;
    public creatinineEligibilityUln?: number;
    public creatinineUnit: string;
    public creatinineClearance?: number;
    public creatinineClearanceEligibilityLln?: number;
    public sodium?: number;
    public sodiumNotDone?: boolean;
    public sodiumLln?: number;
    public sodiumUln?: number;
    public potassium?: number;
    public potassiumNotDone?: boolean;
    public potassiumLln?: number;
    public potassiumUln?: number;
    public chloride?: number;
    public chlorideNotDone?: boolean;
    public chlorideLln?: number;
    public chlorideUln?: number;
    public bicarbonate?: number;
    public bicarbonateNotDone?: boolean;
    public bicarbonateLln?: number;
    public bicarbonateUln?: number;
    public correctedCalcium?: number;
    public correctedCalciumNotDone?: boolean;
    public correctedCalciumLln?: number;
    public correctedCalciumUln?: number;
    public magnesium?: number;
    public magnesiumNotDone?: boolean;
    public magnesiumLln?: number;
    public magnesiumUln?: number;
    public ldh?: number;
    public ldhNotDone?: boolean;
    public ldhLln?: number;
    public ldhUln?: number;
    public bloodGlucose?: number;
    public bloodGlucoseNotDone?: boolean;
    public bloodGlucoseLln?: number;
    public bloodGlucoseUln?: number;
    public totalProtein?: number;
    public totalProteinNotDone?: boolean;
    public totalProteinLln?: number;
    public totalProteinUln?: number;
    public amylase?: number;
    public amylaseNotDone?: boolean;
    public amylaseLln?: number;
    public amylaseUln?: number;
    public lipase?: number;
    public lipaseNotDone?: boolean;
    public lipaseLln?: number;
    public lipaseUln?: number;
    public fastingSerumCortisol?: number;
    public fastingSerumCortisolNotDone?: boolean;
    public fastingSerumCortisolLln?: number;
    public fastingSerumCortisolUln?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Biochemistry>) { (Object as any).assign(this, init); }
}

export class Coagulation implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public pt?: number;
    public ptNotDone?: boolean;
    public ptLln?: number;
    public ptUln?: number;
    public ptEligibilityUln?: number;
    public ptUnit: string;
    public appt?: number;
    public apptNotDone?: boolean;
    public apptLln?: number;
    public apptUln?: number;
    public apptUnit: string;
    public inr?: number;
    public inrNotDone?: boolean;
    public inrLln?: number;
    public inrUln?: number;
    public inrEligibilityUln?: number;
    public inrUnit: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Coagulation>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationBaseline implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public genericMedicationName: string;
    public indication: string;
    public indicationSpec: string;
    public started: string;
    public stopped: string;
    public ongoing: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedicationBaseline>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationOnStudy implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public genericMedicationName: string;
    public indication: string;
    public indicationSpec: string;
    public started?: string;
    public stopped?: string;
    public ongoing: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedicationOnStudy>) { (Object as any).assign(this, init); }
}

export class DurvalumabCycleDates implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public eventName: string;
    public administered?: boolean;
    public administeredSpec: string;
    public date?: string;
    public doseAdministered?: number;
    public expectedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public lastDose?: boolean;
    public lastDoseSpec: string;
    public weight?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DurvalumabCycleDates>) { (Object as any).assign(this, init); }
}

export class DurvalumabTreatment implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public eventName: string;
    public administered?: boolean;
    public administeredSpec: string;
    public date?: string;
    public doseAdministered?: number;
    public expectedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public lastDose?: boolean;
    public lastDoseSpec: string;
    public weight?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DurvalumabTreatment>) { (Object as any).assign(this, init); }
}

export class Ecg implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public qtcf?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Ecg>) { (Object as any).assign(this, init); }
}

export class GoserelinTreatment implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public eventName: string;
    public administered?: boolean;
    public date?: string;
    public injectionWithin: string;
    public injectionWithinReason: string;
    public injectionWithinSpec: string;
    public lastAdministration: string;
    public lastAdministrationReason: string;
    public lastAdministrationSpec: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GoserelinTreatment>) { (Object as any).assign(this, init); }
}

export class Haematology implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public haemoglobin?: number;
    public haemoglobinNotDone?: boolean;
    public haemoglobinLln?: number;
    public haemoglobinUln?: number;
    public haemoglobinUnit: string;
    public neutrophilCount?: number;
    public neutrophilCountNotDone?: boolean;
    public neutrophilCountLln?: number;
    public neutrophilCountUln?: number;
    public neutrophilCountEligibilityLln?: number;
    public platelets?: number;
    public plateletsEligibilityLln?: number;
    public plateletsNotDone?: boolean;
    public plateletsLln?: number;
    public plateletsUln?: number;
    public lymphocyteCount?: number;
    public lymphocyteCountNotDone?: boolean;
    public lymphocyteCountLln?: number;
    public lymphocyteCountUln?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Haematology>) { (Object as any).assign(this, init); }
}

export class LiverEnzymePanel implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public totalBilirubin?: number;
    public totalBilirubinNotDone?: boolean;
    public totalBilirubinLln?: number;
    public totalBilirubinUln?: number;
    public totalBilirubinEligibilityUln?: number;
    public totalBilirubinUnit: string;
    public alp?: number;
    public alpNotDone?: boolean;
    public alpLln?: number;
    public alpUln?: number;
    public alpUnit: string;
    public alt?: number;
    public altNotDone?: boolean;
    public altLln?: number;
    public altUln?: number;
    public altEligibilityUln?: number;
    public altUnit: string;
    public ast?: number;
    public astNotDone?: boolean;
    public astLln?: number;
    public astUln?: number;
    public astUnit: string;
    public ggt?: number;
    public ggtNotDone?: boolean;
    public ggtLln?: number;
    public ggtUln?: number;
    public ggtUnit: string;
    public totalAlbumin?: number;
    public totalAlbuminNotDone?: boolean;
    public totalAlbuminLln?: number;
    public totalAlbuminUln?: number;
    public totalAlbuminUnit: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<LiverEnzymePanel>) { (Object as any).assign(this, init); }
}

export class LvefAssessment implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public type: string;
    public lvef?: number;
    public lvefLln?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<LvefAssessment>) { (Object as any).assign(this, init); }
}

export class NewLesion implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public lesionNumber: string;
    public date?: string;
    public methodOfMeasurement: string;
    public methodOfMeasurementSpec: string;
    public site: string;
    public siteSpec: string;
    public locationWithinOrgan: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NewLesion>) { (Object as any).assign(this, init); }
}

export class NonMeasurableLesion implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public timepoint: string;
    public notDoneReason: string;
    public lesion1Status: string;
    public lesion2Status: string;
    public lesion3Status: string;
    public lesion4Status: string;
    public lesion5Status: string;
    public nonTargetLesionResponse: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NonMeasurableLesion>) { (Object as any).assign(this, init); }
}

export class OverallResponse implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public response: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OverallResponse>) { (Object as any).assign(this, init); }
}

export class SurvivalFollowUp implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public timepoint: string;
    public dateOfContact?: string;
    public followUpBy: string;
    public anyChanges: string;
    public trastuzumab: string;
    public endocrine: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurvivalFollowUp>) { (Object as any).assign(this, init); }
}

export class TargetMeasurableLesion implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public timepoint: string;
    public notDoneReason: string;
    public lesion1Measurement?: number;
    public lesion2Measurement?: number;
    public lesion3Measurement?: number;
    public lesion4Measurement?: number;
    public lesion5Measurement?: number;
    public sumOfAllTargetLesions?: number;
    public targetLesionResponse: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TargetMeasurableLesion>) { (Object as any).assign(this, init); }
}

export class ThyroidFunction implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public date?: string;
    public tsh?: number;
    public tshNotDone?: boolean;
    public tshLln?: number;
    public tshUln?: number;
    public t3?: number;
    public t3NotDone?: boolean;
    public t3Lln?: number;
    public t3Uln?: number;
    public t4?: number;
    public t4NotDone?: boolean;
    public t4Lln?: number;
    public t4Uln?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ThyroidFunction>) { (Object as any).assign(this, init); }
}

export class TrastuzumabTreatment implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public eventName: string;
    public administered?: boolean;
    public administeredSpec: string;
    public date?: string;
    public doseAdministered?: number;
    public weight?: number;
    public intendedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public lastDose?: boolean;
    public lastDoseSpec: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TrastuzumabTreatment>) { (Object as any).assign(this, init); }
}

export class TremelimumabTreatment implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public eventName: string;
    public administered?: boolean;
    public administeredSpec: string;
    public date?: string;
    public doseAdministered?: number;
    public expectedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public lastDose?: boolean;
    public lastDoseSpec: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TremelimumabTreatment>) { (Object as any).assign(this, init); }
}

export class UnscheduledTrastuzumab implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public date?: string;
    public doseAdministered?: number;
    public intendedDose?: number;
    public doseDelayed?: boolean;
    public doseDelayedReason: string;
    public doseDelayedSpec: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UnscheduledTrastuzumab>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public piSignOffId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    public repeat?: number;
    public question: string;
    public response: string;
    public comment: string;
    public status?: QueryStatus;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class ParticipantStudySummary implements IPISignOffForm
{
    public id?: number;
    public patientId?: number;
    public piSignOffId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public completeDate?: string;
    public cancelDate?: string;
    public dataDate?: string;
    public dosesTremelimumabIp: string;
    public dosesDurvalumabIp: string;
    public dosesTrastuzumabIp: string;
    public dosesDurvalumabTp: string;
    public dosesTrastuzumabTp: string;
    public dosesTremelimumabSingleDose: string;
    public dosesDurvalumabSingleDose: string;
    public dosesTrastuzumabSingleDose: string;
    public priorLinesOfTherapy?: number;
    public treatmentCohort: string;
    public plannedStartDate?: string;
    public trastuzumabDiscontinued: string;
    public trastuzumabDiscontinuedSpec: string;
    public aromataseNotApplicable?: boolean;
    public goserelinNotApplicable?: boolean;
    // @Ignore()
    public tremelimumabCycles: TremelimumabTreatment[];

    // @Ignore()
    public durvalumabCycles: DurvalumabTreatment[];

    // @Ignore()
    public trastuzumabCycles: TrastuzumabTreatment[];

    // @Ignore()
    public unscheduledTrastuzumabs: UnscheduledTrastuzumab[];

    // @Ignore()
    public aromataseTreatments: AromataseTreatment[];

    // @Ignore()
    public goserelinTreatments: GoserelinTreatment[];

    public gilbertSyndrome: string;
    // @Ignore()
    public haematology: Haematology[];

    // @Ignore()
    public biochemistry: Biochemistry[];

    // @Ignore()
    public liverEnzymePanel: LiverEnzymePanel[];

    // @Ignore()
    public thyroidFunction: ThyroidFunction[];

    // @Ignore()
    public coagulation: Coagulation[];

    // @Ignore()
    public lvefAssessment: LvefAssessment[];

    // @Ignore()
    public ecg: Ecg[];

    // @Ignore()
    public cycleDates: DurvalumabCycleDates[];

    // @Ignore()
    public adverseEvents: AdverseEvent[];

    // @Ignore()
    public baselineConcomitantMedications: ConcomitantMedicationBaseline[];

    // @Ignore()
    public onStudyConcomitantMedications: ConcomitantMedicationOnStudy[];

    public cycle1Biopsy1NotDone?: boolean;
    public cycle1Biopsy1Date?: string;
    public cycle1Biopsy1Site: string;
    public cycle1Biopsy1SiteSpec: string;
    public cycle1Biopsy1Comments: string;
    public cycle1Biopsy1OestrogenNotDone?: boolean;
    public cycle1Biopsy1OestrogenReason: string;
    public cycle1Biopsy1OestrogenSign: string;
    public cycle1Biopsy1OestrogenPercentage?: number;
    public cycle1Biopsy1Ihc: string;
    public cycle1Biopsy1Ish: string;
    public cycle1Biopsy1IshNotDone?: boolean;
    public cycle1Biopsy1IshReason: string;
    public cycle1Biopsy1Erbb2?: number;
    public cycle1Biopsy1MeanGene?: number;
    public cycle1Biopsy1TilAssessed: string;
    public cycle1Biopsy1TilReason: string;
    public cycle1Biopsy1TilSign: string;
    public cycle1Biopsy1TilPercentage?: number;
    public cycle1Biopsy2NotDone?: boolean;
    public cycle1Biopsy2Date?: string;
    public cycle1Biopsy2Site: string;
    public cycle1Biopsy2SiteSpec: string;
    public cycle1Biopsy2Comments: string;
    public cycle1Biopsy2OestrogenNotDone?: boolean;
    public cycle1Biopsy2OestrogenReason: string;
    public cycle1Biopsy2OestrogenSign: string;
    public cycle1Biopsy2OestrogenPercentage?: number;
    public cycle1Biopsy2Ihc: string;
    public cycle1Biopsy2Ish: string;
    public cycle1Biopsy2IshNotDone?: boolean;
    public cycle1Biopsy2IshReason: string;
    public cycle1Biopsy2Erbb2?: number;
    public cycle1Biopsy2MeanGene?: number;
    public cycle1Biopsy2TilAssessed: string;
    public cycle1Biopsy2TilReason: string;
    public cycle1Biopsy2TilSign: string;
    public cycle1Biopsy2TilPercentage?: number;
    public progressionBiopsy1NotDone?: boolean;
    public progressionBiopsy1Date?: string;
    public progressionBiopsy1Site: string;
    public progressionBiopsy1SiteSpec: string;
    public progressionBiopsy1Comments: string;
    public progressionBiopsy1OestrogenNotDone?: boolean;
    public progressionBiopsy1OestrogenReason: string;
    public progressionBiopsy1OestrogenSign: string;
    public progressionBiopsy1OestrogenPercentage?: number;
    public progressionBiopsy1Ihc: string;
    public progressionBiopsy1Ish: string;
    public progressionBiopsy1IshNotDone?: boolean;
    public progressionBiopsy1IshReason: string;
    public progressionBiopsy1Erbb2?: number;
    public progressionBiopsy1MeanGene?: number;
    public progressionBiopsy1TilAssessed: string;
    public progressionBiopsy1TilReason: string;
    public progressionBiopsy1TilSign: string;
    public progressionBiopsy1TilPercentage?: number;
    public progressionBiopsy2NotDone?: boolean;
    public progressionBiopsy2Date?: string;
    public progressionBiopsy2Site: string;
    public progressionBiopsy2SiteSpec: string;
    public progressionBiopsy2Comments: string;
    public progressionBiopsy2OestrogenNotDone?: boolean;
    public progressionBiopsy2OestrogenReason: string;
    public progressionBiopsy2OestrogenSign: string;
    public progressionBiopsy2OestrogenPercentage?: number;
    public progressionBiopsy2Ihc: string;
    public progressionBiopsy2Ish: string;
    public progressionBiopsy2IshNotDone?: boolean;
    public progressionBiopsy2IshReason: string;
    public progressionBiopsy2Erbb2?: number;
    public progressionBiopsy2MeanGene?: number;
    public progressionBiopsy2TilAssessed: string;
    public progressionBiopsy2TilReason: string;
    public progressionBiopsy2TilSign: string;
    public progressionBiopsy2TilPercentage?: number;
    public targetMeasurableLesion1Site: string;
    public targetMeasurableLesion1SiteSpec: string;
    public targetMeasurableLesion1OrganLocation: string;
    public targetMeasurableLesion2Site: string;
    public targetMeasurableLesion2SiteSpec: string;
    public targetMeasurableLesion2OrganLocation: string;
    public targetMeasurableLesion3Site: string;
    public targetMeasurableLesion3SiteSpec: string;
    public targetMeasurableLesion3OrganLocation: string;
    public targetMeasurableLesion4Site: string;
    public targetMeasurableLesion4SiteSpec: string;
    public targetMeasurableLesion4OrganLocation: string;
    public targetMeasurableLesion5Site: string;
    public targetMeasurableLesion5SiteSpec: string;
    public targetMeasurableLesion5OrganLocation: string;
    public nonMeasurableLesion1Site: string;
    public nonMeasurableLesion1SiteSpec: string;
    public nonMeasurableLesion1OrganLocation: string;
    public nonMeasurableLesion2Site: string;
    public nonMeasurableLesion2SiteSpec: string;
    public nonMeasurableLesion2OrganLocation: string;
    public nonMeasurableLesion3Site: string;
    public nonMeasurableLesion3SiteSpec: string;
    public nonMeasurableLesion3OrganLocation: string;
    public nonMeasurableLesion4Site: string;
    public nonMeasurableLesion4SiteSpec: string;
    public nonMeasurableLesion4OrganLocation: string;
    public nonMeasurableLesion5Site: string;
    public nonMeasurableLesion5SiteSpec: string;
    public nonMeasurableLesion5OrganLocation: string;
    // @Ignore()
    public targetMeasurableLesions: TargetMeasurableLesion[];

    // @Ignore()
    public nonMeasurableLesions: NonMeasurableLesion[];

    // @Ignore()
    public newLesions: NewLesion[];

    // @Ignore()
    public overallResponses: OverallResponse[];

    public eventCrfId?: number;
    public dateOfProgression?: string;
    public methodForDetecting: string;
    public methodForDetectingSpec: string;
    public wasAConfirmatory: string;
    public wasAConfirmatoryReason: string;
    public dateOfConfirmation?: string;
    public methodOfConfirmation: string;
    public methodOfConfirmationSpec: string;
    public wasNewTreatment: string;
    public wasNewTreatmentSpec: string;
    public haveConfirmedProgression: string;
    public cnsDirectedTherapy: string;
    public cnsDirectedTherapySpec: string;
    public dateOfDeath?: string;
    public causeOfDeath: string;
    public causeOfDeathSpec: string;
    // @Ignore()
    public survivalFollowUps: SurvivalFollowUp[];

    // @Ignore()
    public queries: Query[];

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ParticipantStudySummary>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PISignOffPatient implements IPISignOffPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public investigatorIds: number[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOffPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class PISignOffRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public investigatorIds: number[];
    public piSignOffId?: number;
    public investigatorId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<PISignOffRequestContext>) { (Object as any).assign(this, init); }
}

export enum PISignOffPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewPISignOff = 7,
    ViewPISignOffForm = 8,
    ViewQuery = 9,
    CreatePISignOff = 10,
    CompletePISignOff = 11,
    CancelPISignOff = 12,
    CompletePISignOffForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    CancelQuery = 17,
    ViewAllPatients = 18,
    UpdatePISignOff = 19,
}

export enum InvestigatorType
{
    PrincipalInvestigator = 1,
    CoInvestigator = 2,
}

export class InvestigatorContext
{
    public active?: boolean;
    public type?: InvestigatorType;

    public constructor(init?: Partial<InvestigatorContext>) { (Object as any).assign(this, init); }
}

export class Investigator implements IInvestigator
{
    public id?: number;
    public onTrial: InvestigatorContext[];
    public masterGroups: { [index: number]: InvestigatorContext[]; };
    public coordinatingGroups: { [index: number]: InvestigatorContext[]; };
    public institutions: { [index: number]: InvestigatorContext[]; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Investigator>) { (Object as any).assign(this, init); }
}

export class PISignOff implements IPISignOff
{
    public id?: number;
    public patientId?: number;
    public piSignOffBatchId?: number;
    public formCount?: number;
    public investigatorId?: number;
    public status?: PISignOffStatus;
    public dataDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOff>) { (Object as any).assign(this, init); }
}

export interface IPISignOffGetCollectionByPISignOffBatchId extends IGet
{
    piSignOffBatchId?: number;
}

export interface IPISignOffGetSingleByPISignOffBatchIdAndPatientId extends IGet
{
    piSignOffBatchId?: number;
    patientId?: number;
}

export class PISignOffUpdateOptions extends PISignOffBatchCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<PISignOffUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IPISignOffPostUpdateSignOff extends IPost
{
    metadata: { [index: string]: Object; };
    piSignOffId?: number;
    options: PISignOffUpdateOptions;
}

export interface IPISignOffPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class PISignOffBatch implements IPISignOffBatch
{
    public id?: number;
    public piSignOffDefinitionId?: number;
    public repeat?: number;
    public status?: PISignOffStatus;
    public count?: number;
    public created?: number;
    public completeDate?: string;
    public cancelDate?: string;
    public formDefinitionIds: number[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PISignOffBatch>) { (Object as any).assign(this, init); }
}

export interface IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat extends IGet
{
    piSignOffDefinitionId?: number;
    repeat?: number;
}

export interface IQueryGetCollectionByPISignOffId extends IGet
{
    piSignOffId?: number;
}

export enum UpdatedFormPropertyType
{
    Normal = 1,
    DataChange = 2,
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public type?: UpdatedFormPropertyType;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class PISignOffDefinition implements IPISignOffDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<PISignOffDefinition>) { (Object as any).assign(this, init); }
}

export interface IPISignOffForm extends IForm, IHasPatientId
{
    piSignOffId?: number;
    status?: PISignOffStatus;
    dataDate?: string;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IMasterGroup extends IDataModel, IHasCode
{
    name: string;
}

export interface IHasCode
{
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId, IHasCode
{
    name: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel, IHasCode
{
    name: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId, IHasCode
{
    name: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPISignOffPatient extends IPatient
{
    treatmentArm: string;
    investigatorIds: number[];
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IInvestigator extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial: InvestigatorContext[];
    masterGroups: { [index: number]: InvestigatorContext[]; };
    coordinatingGroups: { [index: number]: InvestigatorContext[]; };
    institutions: { [index: number]: InvestigatorContext[]; };
}

export interface IPISignOff extends IDataModel
{
    patientId?: number;
    piSignOffBatchId?: number;
    formCount?: number;
    investigatorId?: number;
    status?: PISignOffStatus;
    completeDate?: string;
    cancelDate?: string;
    dataDate?: string;
}

export interface IPISignOffBatch extends IDataModel
{
    piSignOffDefinitionId?: number;
    status?: PISignOffStatus;
    repeat?: number;
    count?: number;
    created?: number;
    formDefinitionIds: number[];
    completeDate?: string;
    cancelDate?: string;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    piSignOffId?: number;
    propertyName: string;
    parentQueryId?: number;
    repeat?: number;
    question: string;
    response: string;
    comment: string;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
    type?: UpdatedFormPropertyType;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IPISignOffDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class CreatePISignOffResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreatePISignOffResponse>) { (Object as any).assign(this, init); }
}

export class SendPISignOffEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendPISignOffEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: PISignOffPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdverseEvent[];

    public constructor(init?: Partial<AdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiHer2TherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiHer2TherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AntiHer2Therapy[];

    public constructor(init?: Partial<AntiHer2TherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiHer2TherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AntiHer2TherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AromataseTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AromataseTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AromataseTreatment[];

    public constructor(init?: Partial<AromataseTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AromataseTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AromataseTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Biochemistry[];

    public constructor(init?: Partial<BiochemistryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BiochemistryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Coagulation[];

    public constructor(init?: Partial<CoagulationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoagulationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoagulationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationBaselineSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselineSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationBaselineCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline[];

    public constructor(init?: Partial<ConcomitantMedicationBaselineCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationBaselineValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationBaselineValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationOnStudySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationOnStudyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy[];

    public constructor(init?: Partial<ConcomitantMedicationOnStudyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationOnStudyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabCycleDatesSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DurvalumabCycleDates;

    public constructor(init?: Partial<DurvalumabCycleDatesSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabCycleDatesCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DurvalumabCycleDates[];

    public constructor(init?: Partial<DurvalumabCycleDatesCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabCycleDatesValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DurvalumabCycleDatesValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DurvalumabTreatment[];

    public constructor(init?: Partial<DurvalumabTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DurvalumabTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DurvalumabTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Ecg[];

    public constructor(init?: Partial<EcgCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<EcgValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GoserelinTreatment[];

    public constructor(init?: Partial<GoserelinTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GoserelinTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Haematology[];

    public constructor(init?: Partial<HaematologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<HaematologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LiverEnzymePanelSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LiverEnzymePanelCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LiverEnzymePanel[];

    public constructor(init?: Partial<LiverEnzymePanelCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LiverEnzymePanelValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LiverEnzymePanelValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LvefAssessmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LvefAssessmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LvefAssessment[];

    public constructor(init?: Partial<LvefAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LvefAssessmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LvefAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NewLesion[];

    public constructor(init?: Partial<NewLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NewLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonMeasurableLesion[];

    public constructor(init?: Partial<NonMeasurableLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NonMeasurableLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponseSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OverallResponse[];

    public constructor(init?: Partial<OverallResponseCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OverallResponseValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurvivalFollowUp[];

    public constructor(init?: Partial<SurvivalFollowUpCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurvivalFollowUpValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurvivalFollowUpValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion[];

    public constructor(init?: Partial<TargetMeasurableLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TargetMeasurableLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ThyroidFunction[];

    public constructor(init?: Partial<ThyroidFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ThyroidFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TrastuzumabTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TrastuzumabTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment[];

    public constructor(init?: Partial<TrastuzumabTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TrastuzumabTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TrastuzumabTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TremelimumabTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TremelimumabTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TremelimumabTreatment[];

    public constructor(init?: Partial<TremelimumabTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TremelimumabTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TremelimumabTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnscheduledTrastuzumabSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnscheduledTrastuzumabCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab[];

    public constructor(init?: Partial<UnscheduledTrastuzumabCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnscheduledTrastuzumabValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UnscheduledTrastuzumabValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummarySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummarySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummaryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ParticipantStudySummary[];

    public constructor(init?: Partial<ParticipantStudySummaryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParticipantStudySummaryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ParticipantStudySummaryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: PISignOffPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: PISignOffDefinition;

    public constructor(init?: Partial<PISignOffDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: PISignOffDefinition[];

    public constructor(init?: Partial<PISignOffDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Investigator[];

    public constructor(init?: Partial<InvestigatorCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InvestigatorValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InvestigatorValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: PISignOff[];

    public constructor(init?: Partial<PISignOffCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffValidationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<PISignOffValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: PISignOffBatch[];

    public constructor(init?: Partial<PISignOffBatchCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PISignOffBatchValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PISignOffBatchCreationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

// @Route("/test")
export class Test extends NativeTypesBase implements IGet
{

    public constructor(init?: Partial<Test>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'Test'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class CreatePISignOff implements IReturn<CreatePISignOffResponse>
{
    public responseStatus: ResponseStatus;
    public piSignOffBatchId?: number;
    public patientId?: number;
    public options: PISignOffBatchCreationOptions;

    public constructor(init?: Partial<CreatePISignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePISignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreatePISignOffResponse(); }
}

export class SendPISignOffCompleteEmail implements IReturn<SendPISignOffEmailResponse>
{
    public piSignOffId?: number;

    public constructor(init?: Partial<SendPISignOffCompleteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPISignOffCompleteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class QueueAvailablePISignOffEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailablePISignOffEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueAvailablePISignOffEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class SendPISignOffsAvailableEmails implements IReturn<SendPISignOffEmailResponse>
{
    public investigatorId?: number;

    public constructor(init?: Partial<SendPISignOffsAvailableEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPISignOffsAvailableEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendPISignOffEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class QueueInvestigatorQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInvestigatorQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInvestigatorQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/investigator-query-summary/{InvestigatorId}")
export class SendInvestigatorQuerySummaryEmail implements IReturn<SendPISignOffEmailResponse>
{
    public investigatorId?: number;

    public constructor(init?: Partial<SendInvestigatorQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInvestigatorQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendNewQueryAddedEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAddedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewQueryAddedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendNewQueryAsResponseEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAsResponseEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewQueryAsResponseEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendQueryClosedEarlyEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryClosedEarlyEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendQueryClosedEarlyEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

export class SendQueryResponseEmail implements IReturn<SendPISignOffEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryResponseEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendQueryResponseEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPISignOffEmailResponse(); }
}

// @Route("/pi-signoff/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
}

// @Route("/diamondapi/report/queries")
export class QueryReport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<QueryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/diamondapi/export/queries/new")
export class NewQueryExport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<NewQueryExport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewQueryExport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/diamondapi/export/queries/more-information")
export class MoreInformationQueryExport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<MoreInformationQueryExport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MoreInformationQueryExport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/diamondapi/export/queries/cancelled")
export class CancelledQueryExport
{
    public institutionId?: number;
    public patientId?: number;
    public piSignOffId?: number;

    public constructor(init?: Partial<CancelledQueryExport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelledQueryExport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/pi-signoff/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/pi-signoff/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/pi-signoff/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/pi-signoff/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserGetSingle'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserSingleResponse(); }
}

// @Route("/pi-signoff/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/pi-signoff/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

// @Route("/pi-signoff/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/pi-signoff/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeCollectionResponse(); }
}

// @Route("/pi-signoff/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/pi-signoff/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/pi-signoff/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

// @Route("/pi-signoff/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/pi-signoff/form/adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleById implements IReturn<AdverseEventSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/pi-signoff/form/adverse-event/collection", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollection implements IReturn<AdverseEventCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

// @Route("/pi-signoff/form/adverse-event/create", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostCreate implements IReturn<AdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/pi-signoff/form/adverse-event/save", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSave implements IReturn<AdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/pi-signoff/form/adverse-event/validate", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidate implements IReturn<AdverseEventValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

// @Route("/pi-signoff/form/adverse-event/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByPISignOffIdAndRepeat implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/pi-signoff/form/adverse-event/single/update", "POST")
// @Api(Description="Form - AdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostUpdateById implements IReturn<AdverseEventSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

// @Route("/pi-signoff/form/anti-her2-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyGetSingleById implements IReturn<AntiHer2TherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AntiHer2TherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/pi-signoff/form/anti-her2-therapy/collection", "GET")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyGetCollection implements IReturn<AntiHer2TherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AntiHer2TherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapyCollectionResponse(); }
}

// @Route("/pi-signoff/form/anti-her2-therapy/create", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostCreate implements IReturn<AntiHer2TherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/pi-signoff/form/anti-her2-therapy/save", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostSave implements IReturn<AntiHer2TherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/pi-signoff/form/anti-her2-therapy/validate", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapyValidationResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostValidate implements IReturn<AntiHer2TherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AntiHer2Therapy;

    public constructor(init?: Partial<AntiHer2TherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AntiHer2TherapyValidationResponse(); }
}

// @Route("/pi-signoff/form/anti-her2-therapy/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyGetSingleByPISignOffIdAndRepeat implements IReturn<AntiHer2TherapySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AntiHer2TherapyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/pi-signoff/form/anti-her2-therapy/single/update", "POST")
// @Api(Description="Form - AntiHer2Therapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiHer2TherapySingleResponse)", StatusCode=500)
// @DataContract
export class AntiHer2TherapyPostUpdateById implements IReturn<AntiHer2TherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AntiHer2TherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AntiHer2TherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AntiHer2TherapySingleResponse(); }
}

// @Route("/pi-signoff/form/aromatase-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentGetSingleById implements IReturn<AromataseTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AromataseTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/aromatase-treatment/collection", "GET")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentGetCollection implements IReturn<AromataseTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AromataseTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentCollectionResponse(); }
}

// @Route("/pi-signoff/form/aromatase-treatment/create", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostCreate implements IReturn<AromataseTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/aromatase-treatment/save", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostSave implements IReturn<AromataseTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/aromatase-treatment/validate", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostValidate implements IReturn<AromataseTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AromataseTreatment;

    public constructor(init?: Partial<AromataseTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AromataseTreatmentValidationResponse(); }
}

// @Route("/pi-signoff/form/aromatase-treatment/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentGetSingleByPISignOffIdAndRepeat implements IReturn<AromataseTreatmentSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AromataseTreatmentGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/aromatase-treatment/single/update", "POST")
// @Api(Description="Form - AromataseTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AromataseTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AromataseTreatmentPostUpdateById implements IReturn<AromataseTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AromataseTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AromataseTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AromataseTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/biochemistry/single/id/{Id}", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetSingleById implements IReturn<BiochemistrySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BiochemistryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/pi-signoff/form/biochemistry/collection", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetCollection implements IReturn<BiochemistryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BiochemistryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistryCollectionResponse(); }
}

// @Route("/pi-signoff/form/biochemistry/create", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostCreate implements IReturn<BiochemistrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/pi-signoff/form/biochemistry/save", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostSave implements IReturn<BiochemistrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/pi-signoff/form/biochemistry/validate", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostValidate implements IReturn<BiochemistryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiochemistryValidationResponse(); }
}

// @Route("/pi-signoff/form/biochemistry/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetSingleByPISignOffIdAndRepeat implements IReturn<BiochemistrySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BiochemistryGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/pi-signoff/form/biochemistry/single/update", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostUpdateById implements IReturn<BiochemistrySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BiochemistryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiochemistryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiochemistrySingleResponse(); }
}

// @Route("/pi-signoff/form/coagulation/single/id/{Id}", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetSingleById implements IReturn<CoagulationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoagulationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/pi-signoff/form/coagulation/collection", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationCollectionResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetCollection implements IReturn<CoagulationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoagulationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationCollectionResponse(); }
}

// @Route("/pi-signoff/form/coagulation/create", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostCreate implements IReturn<CoagulationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/pi-signoff/form/coagulation/save", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostSave implements IReturn<CoagulationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/pi-signoff/form/coagulation/validate", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationValidationResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostValidate implements IReturn<CoagulationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Coagulation;

    public constructor(init?: Partial<CoagulationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationValidationResponse(); }
}

// @Route("/pi-signoff/form/coagulation/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationGetSingleByPISignOffIdAndRepeat implements IReturn<CoagulationSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CoagulationGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/pi-signoff/form/coagulation/single/update", "POST")
// @Api(Description="Form - Coagulation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CoagulationSingleResponse)", StatusCode=500)
// @DataContract
export class CoagulationPostUpdateById implements IReturn<CoagulationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CoagulationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoagulationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoagulationSingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-baseline/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselineGetSingleById implements IReturn<ConcomitantMedicationBaselineSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationBaselineGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselineGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-baseline/collection", "GET")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselineGetCollection implements IReturn<ConcomitantMedicationBaselineCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationBaselineGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselineGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineCollectionResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-baseline/create", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostCreate implements IReturn<ConcomitantMedicationBaselineSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-baseline/save", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostSave implements IReturn<ConcomitantMedicationBaselineSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-baseline/validate", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostValidate implements IReturn<ConcomitantMedicationBaselineValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationBaseline;

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationBaselineValidationResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-baseline/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselineGetSingleByPISignOffIdAndRepeat implements IReturn<ConcomitantMedicationBaselineSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationBaselineGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselineGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-baseline/single/update", "POST")
// @Api(Description="Form - ConcomitantMedicationBaseline")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationBaselineSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationBaselinePostUpdateById implements IReturn<ConcomitantMedicationBaselineSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationBaselinePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationBaselinePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationBaselineSingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-on-study/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyGetSingleById implements IReturn<ConcomitantMedicationOnStudySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-on-study/collection", "GET")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyGetCollection implements IReturn<ConcomitantMedicationOnStudyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudyCollectionResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-on-study/create", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostCreate implements IReturn<ConcomitantMedicationOnStudySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-on-study/save", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostSave implements IReturn<ConcomitantMedicationOnStudySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-on-study/validate", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudyValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostValidate implements IReturn<ConcomitantMedicationOnStudyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedicationOnStudy;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationOnStudyValidationResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-on-study/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyGetSingleByPISignOffIdAndRepeat implements IReturn<ConcomitantMedicationOnStudySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationOnStudyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/pi-signoff/form/concomitant-medication-on-study/single/update", "POST")
// @Api(Description="Form - ConcomitantMedicationOnStudy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationOnStudySingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationOnStudyPostUpdateById implements IReturn<ConcomitantMedicationOnStudySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationOnStudyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationOnStudyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationOnStudySingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-cycle-dates/single/id/{Id}", "GET")
// @Api(Description="Form - DurvalumabCycleDates")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabCycleDatesGetSingleById implements IReturn<DurvalumabCycleDatesSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DurvalumabCycleDatesGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabCycleDatesGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabCycleDatesSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-cycle-dates/collection", "GET")
// @Api(Description="Form - DurvalumabCycleDates")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesCollectionResponse)", StatusCode=500)
// @DataContract
export class DurvalumabCycleDatesGetCollection implements IReturn<DurvalumabCycleDatesCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DurvalumabCycleDatesGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabCycleDatesGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabCycleDatesCollectionResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-cycle-dates/create", "POST")
// @Api(Description="Form - DurvalumabCycleDates")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabCycleDatesPostCreate implements IReturn<DurvalumabCycleDatesSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabCycleDates;

    public constructor(init?: Partial<DurvalumabCycleDatesPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabCycleDatesPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabCycleDatesSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-cycle-dates/save", "POST")
// @Api(Description="Form - DurvalumabCycleDates")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabCycleDatesPostSave implements IReturn<DurvalumabCycleDatesSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabCycleDates;

    public constructor(init?: Partial<DurvalumabCycleDatesPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabCycleDatesPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabCycleDatesSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-cycle-dates/validate", "POST")
// @Api(Description="Form - DurvalumabCycleDates")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesValidationResponse)", StatusCode=500)
// @DataContract
export class DurvalumabCycleDatesPostValidate implements IReturn<DurvalumabCycleDatesValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabCycleDates;

    public constructor(init?: Partial<DurvalumabCycleDatesPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabCycleDatesPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabCycleDatesValidationResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-cycle-dates/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - DurvalumabCycleDates")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabCycleDatesGetSingleByPISignOffIdAndRepeat implements IReturn<DurvalumabCycleDatesSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DurvalumabCycleDatesGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabCycleDatesGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabCycleDatesSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-cycle-dates/single/update", "POST")
// @Api(Description="Form - DurvalumabCycleDates")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabCycleDatesSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabCycleDatesPostUpdateById implements IReturn<DurvalumabCycleDatesSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DurvalumabCycleDatesPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabCycleDatesPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabCycleDatesSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentGetSingleById implements IReturn<DurvalumabTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DurvalumabTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-treatment/collection", "GET")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentGetCollection implements IReturn<DurvalumabTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DurvalumabTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentCollectionResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-treatment/create", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostCreate implements IReturn<DurvalumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-treatment/save", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostSave implements IReturn<DurvalumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-treatment/validate", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostValidate implements IReturn<DurvalumabTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DurvalumabTreatment;

    public constructor(init?: Partial<DurvalumabTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabTreatmentValidationResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-treatment/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentGetSingleByPISignOffIdAndRepeat implements IReturn<DurvalumabTreatmentSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DurvalumabTreatmentGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/durvalumab-treatment/single/update", "POST")
// @Api(Description="Form - DurvalumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DurvalumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class DurvalumabTreatmentPostUpdateById implements IReturn<DurvalumabTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DurvalumabTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DurvalumabTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DurvalumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/ecg/single/id/{Id}", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgGetSingleById implements IReturn<EcgSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EcgGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/pi-signoff/form/ecg/collection", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgCollectionResponse)", StatusCode=500)
// @DataContract
export class EcgGetCollection implements IReturn<EcgCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<EcgGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgCollectionResponse(); }
}

// @Route("/pi-signoff/form/ecg/create", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostCreate implements IReturn<EcgSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/pi-signoff/form/ecg/save", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostSave implements IReturn<EcgSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/pi-signoff/form/ecg/validate", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgValidationResponse)", StatusCode=500)
// @DataContract
export class EcgPostValidate implements IReturn<EcgValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Ecg;

    public constructor(init?: Partial<EcgPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgValidationResponse(); }
}

// @Route("/pi-signoff/form/ecg/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgGetSingleByPISignOffIdAndRepeat implements IReturn<EcgSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<EcgGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/pi-signoff/form/ecg/single/update", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostUpdateById implements IReturn<EcgSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<EcgPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgSingleResponse(); }
}

// @Route("/pi-signoff/form/goserelin-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetSingleById implements IReturn<GoserelinTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/goserelin-treatment/collection", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetCollection implements IReturn<GoserelinTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentCollectionResponse(); }
}

// @Route("/pi-signoff/form/goserelin-treatment/create", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostCreate implements IReturn<GoserelinTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/goserelin-treatment/save", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostSave implements IReturn<GoserelinTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/goserelin-treatment/validate", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostValidate implements IReturn<GoserelinTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentValidationResponse(); }
}

// @Route("/pi-signoff/form/goserelin-treatment/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetSingleByPISignOffIdAndRepeat implements IReturn<GoserelinTreatmentSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/goserelin-treatment/single/update", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostUpdateById implements IReturn<GoserelinTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GoserelinTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/haematology/single/id/{Id}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleById implements IReturn<HaematologySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HaematologyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/pi-signoff/form/haematology/collection", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetCollection implements IReturn<HaematologyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<HaematologyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologyCollectionResponse(); }
}

// @Route("/pi-signoff/form/haematology/create", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostCreate implements IReturn<HaematologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/pi-signoff/form/haematology/save", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostSave implements IReturn<HaematologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/pi-signoff/form/haematology/validate", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostValidate implements IReturn<HaematologyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologyValidationResponse(); }
}

// @Route("/pi-signoff/form/haematology/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleByPISignOffIdAndRepeat implements IReturn<HaematologySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<HaematologyGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/pi-signoff/form/haematology/single/update", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostUpdateById implements IReturn<HaematologySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<HaematologyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HaematologyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HaematologySingleResponse(); }
}

// @Route("/pi-signoff/form/liver-enzyme-panel/single/id/{Id}", "GET")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelGetSingleById implements IReturn<LiverEnzymePanelSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LiverEnzymePanelGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/pi-signoff/form/liver-enzyme-panel/collection", "GET")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelCollectionResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelGetCollection implements IReturn<LiverEnzymePanelCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LiverEnzymePanelGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelCollectionResponse(); }
}

// @Route("/pi-signoff/form/liver-enzyme-panel/create", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostCreate implements IReturn<LiverEnzymePanelSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/pi-signoff/form/liver-enzyme-panel/save", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostSave implements IReturn<LiverEnzymePanelSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/pi-signoff/form/liver-enzyme-panel/validate", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelValidationResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostValidate implements IReturn<LiverEnzymePanelValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LiverEnzymePanel;

    public constructor(init?: Partial<LiverEnzymePanelPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LiverEnzymePanelValidationResponse(); }
}

// @Route("/pi-signoff/form/liver-enzyme-panel/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelGetSingleByPISignOffIdAndRepeat implements IReturn<LiverEnzymePanelSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<LiverEnzymePanelGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/pi-signoff/form/liver-enzyme-panel/single/update", "POST")
// @Api(Description="Form - LiverEnzymePanel")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LiverEnzymePanelSingleResponse)", StatusCode=500)
// @DataContract
export class LiverEnzymePanelPostUpdateById implements IReturn<LiverEnzymePanelSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<LiverEnzymePanelPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiverEnzymePanelPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LiverEnzymePanelSingleResponse(); }
}

// @Route("/pi-signoff/form/lvef-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentGetSingleById implements IReturn<LvefAssessmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LvefAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/pi-signoff/form/lvef-assessment/collection", "GET")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentGetCollection implements IReturn<LvefAssessmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LvefAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentCollectionResponse(); }
}

// @Route("/pi-signoff/form/lvef-assessment/create", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostCreate implements IReturn<LvefAssessmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/pi-signoff/form/lvef-assessment/save", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostSave implements IReturn<LvefAssessmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/pi-signoff/form/lvef-assessment/validate", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostValidate implements IReturn<LvefAssessmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LvefAssessment;

    public constructor(init?: Partial<LvefAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LvefAssessmentValidationResponse(); }
}

// @Route("/pi-signoff/form/lvef-assessment/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentGetSingleByPISignOffIdAndRepeat implements IReturn<LvefAssessmentSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<LvefAssessmentGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/pi-signoff/form/lvef-assessment/single/update", "POST")
// @Api(Description="Form - LvefAssessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LvefAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class LvefAssessmentPostUpdateById implements IReturn<LvefAssessmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<LvefAssessmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LvefAssessmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LvefAssessmentSingleResponse(); }
}

// @Route("/pi-signoff/form/new-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetSingleById implements IReturn<NewLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NewLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/new-lesion/collection", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetCollection implements IReturn<NewLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NewLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionCollectionResponse(); }
}

// @Route("/pi-signoff/form/new-lesion/create", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostCreate implements IReturn<NewLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/new-lesion/save", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostSave implements IReturn<NewLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/new-lesion/validate", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostValidate implements IReturn<NewLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionValidationResponse(); }
}

// @Route("/pi-signoff/form/new-lesion/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetSingleByPISignOffIdAndRepeat implements IReturn<NewLesionSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NewLesionGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/new-lesion/single/update", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostUpdateById implements IReturn<NewLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NewLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/non-measurable-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetSingleById implements IReturn<NonMeasurableLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/non-measurable-lesion/collection", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetCollection implements IReturn<NonMeasurableLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionCollectionResponse(); }
}

// @Route("/pi-signoff/form/non-measurable-lesion/create", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostCreate implements IReturn<NonMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/non-measurable-lesion/save", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostSave implements IReturn<NonMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/non-measurable-lesion/validate", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostValidate implements IReturn<NonMeasurableLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionValidationResponse(); }
}

// @Route("/pi-signoff/form/non-measurable-lesion/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetSingleByPISignOffIdAndRepeat implements IReturn<NonMeasurableLesionSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/non-measurable-lesion/single/update", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostUpdateById implements IReturn<NonMeasurableLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NonMeasurableLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/overall-response/single/id/{Id}", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetSingleById implements IReturn<OverallResponseSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OverallResponseGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/pi-signoff/form/overall-response/collection", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetCollection implements IReturn<OverallResponseCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OverallResponseGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseCollectionResponse(); }
}

// @Route("/pi-signoff/form/overall-response/create", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostCreate implements IReturn<OverallResponseSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/pi-signoff/form/overall-response/save", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostSave implements IReturn<OverallResponseSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/pi-signoff/form/overall-response/validate", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostValidate implements IReturn<OverallResponseValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseValidationResponse(); }
}

// @Route("/pi-signoff/form/overall-response/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetSingleByPISignOffIdAndRepeat implements IReturn<OverallResponseSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OverallResponseGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/pi-signoff/form/overall-response/single/update", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostUpdateById implements IReturn<OverallResponseSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OverallResponsePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/pi-signoff/form/survival-follow-up/single/id/{Id}", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetSingleById implements IReturn<SurvivalFollowUpSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/pi-signoff/form/survival-follow-up/collection", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpCollectionResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetCollection implements IReturn<SurvivalFollowUpCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpCollectionResponse(); }
}

// @Route("/pi-signoff/form/survival-follow-up/create", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostCreate implements IReturn<SurvivalFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/pi-signoff/form/survival-follow-up/save", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostSave implements IReturn<SurvivalFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/pi-signoff/form/survival-follow-up/validate", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpValidationResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostValidate implements IReturn<SurvivalFollowUpValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurvivalFollowUp;

    public constructor(init?: Partial<SurvivalFollowUpPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpValidationResponse(); }
}

// @Route("/pi-signoff/form/survival-follow-up/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpGetSingleByPISignOffIdAndRepeat implements IReturn<SurvivalFollowUpSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurvivalFollowUpGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/pi-signoff/form/survival-follow-up/single/update", "POST")
// @Api(Description="Form - SurvivalFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurvivalFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class SurvivalFollowUpPostUpdateById implements IReturn<SurvivalFollowUpSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurvivalFollowUpPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurvivalFollowUpPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurvivalFollowUpSingleResponse(); }
}

// @Route("/pi-signoff/form/target-measurable-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetSingleById implements IReturn<TargetMeasurableLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/target-measurable-lesion/collection", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetCollection implements IReturn<TargetMeasurableLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionCollectionResponse(); }
}

// @Route("/pi-signoff/form/target-measurable-lesion/create", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostCreate implements IReturn<TargetMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/target-measurable-lesion/save", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostSave implements IReturn<TargetMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/target-measurable-lesion/validate", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostValidate implements IReturn<TargetMeasurableLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionValidationResponse(); }
}

// @Route("/pi-signoff/form/target-measurable-lesion/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetSingleByPISignOffIdAndRepeat implements IReturn<TargetMeasurableLesionSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/target-measurable-lesion/single/update", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostUpdateById implements IReturn<TargetMeasurableLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TargetMeasurableLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/pi-signoff/form/thyroid-function/single/id/{Id}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleById implements IReturn<ThyroidFunctionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/pi-signoff/form/thyroid-function/collection", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetCollection implements IReturn<ThyroidFunctionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ThyroidFunctionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionCollectionResponse(); }
}

// @Route("/pi-signoff/form/thyroid-function/create", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostCreate implements IReturn<ThyroidFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/pi-signoff/form/thyroid-function/save", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostSave implements IReturn<ThyroidFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/pi-signoff/form/thyroid-function/validate", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostValidate implements IReturn<ThyroidFunctionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionValidationResponse(); }
}

// @Route("/pi-signoff/form/thyroid-function/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleByPISignOffIdAndRepeat implements IReturn<ThyroidFunctionSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/pi-signoff/form/thyroid-function/single/update", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostUpdateById implements IReturn<ThyroidFunctionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ThyroidFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ThyroidFunctionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
}

// @Route("/pi-signoff/form/trastuzumab-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentGetSingleById implements IReturn<TrastuzumabTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TrastuzumabTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/trastuzumab-treatment/collection", "GET")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentGetCollection implements IReturn<TrastuzumabTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TrastuzumabTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentCollectionResponse(); }
}

// @Route("/pi-signoff/form/trastuzumab-treatment/create", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostCreate implements IReturn<TrastuzumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/trastuzumab-treatment/save", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostSave implements IReturn<TrastuzumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/trastuzumab-treatment/validate", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostValidate implements IReturn<TrastuzumabTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TrastuzumabTreatment;

    public constructor(init?: Partial<TrastuzumabTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TrastuzumabTreatmentValidationResponse(); }
}

// @Route("/pi-signoff/form/trastuzumab-treatment/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentGetSingleByPISignOffIdAndRepeat implements IReturn<TrastuzumabTreatmentSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TrastuzumabTreatmentGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/trastuzumab-treatment/single/update", "POST")
// @Api(Description="Form - TrastuzumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TrastuzumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TrastuzumabTreatmentPostUpdateById implements IReturn<TrastuzumabTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TrastuzumabTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TrastuzumabTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TrastuzumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/tremelimumab-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentGetSingleById implements IReturn<TremelimumabTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TremelimumabTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/tremelimumab-treatment/collection", "GET")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentGetCollection implements IReturn<TremelimumabTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TremelimumabTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentCollectionResponse(); }
}

// @Route("/pi-signoff/form/tremelimumab-treatment/create", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostCreate implements IReturn<TremelimumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/tremelimumab-treatment/save", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostSave implements IReturn<TremelimumabTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/tremelimumab-treatment/validate", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostValidate implements IReturn<TremelimumabTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TremelimumabTreatment;

    public constructor(init?: Partial<TremelimumabTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TremelimumabTreatmentValidationResponse(); }
}

// @Route("/pi-signoff/form/tremelimumab-treatment/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentGetSingleByPISignOffIdAndRepeat implements IReturn<TremelimumabTreatmentSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TremelimumabTreatmentGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/tremelimumab-treatment/single/update", "POST")
// @Api(Description="Form - TremelimumabTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TremelimumabTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TremelimumabTreatmentPostUpdateById implements IReturn<TremelimumabTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TremelimumabTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TremelimumabTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TremelimumabTreatmentSingleResponse(); }
}

// @Route("/pi-signoff/form/unscheduled-trastuzumab/single/id/{Id}", "GET")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabGetSingleById implements IReturn<UnscheduledTrastuzumabSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UnscheduledTrastuzumabGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/pi-signoff/form/unscheduled-trastuzumab/collection", "GET")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabCollectionResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabGetCollection implements IReturn<UnscheduledTrastuzumabCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UnscheduledTrastuzumabGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabCollectionResponse(); }
}

// @Route("/pi-signoff/form/unscheduled-trastuzumab/create", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostCreate implements IReturn<UnscheduledTrastuzumabSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/pi-signoff/form/unscheduled-trastuzumab/save", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostSave implements IReturn<UnscheduledTrastuzumabSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/pi-signoff/form/unscheduled-trastuzumab/validate", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabValidationResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostValidate implements IReturn<UnscheduledTrastuzumabValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UnscheduledTrastuzumab;

    public constructor(init?: Partial<UnscheduledTrastuzumabPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnscheduledTrastuzumabValidationResponse(); }
}

// @Route("/pi-signoff/form/unscheduled-trastuzumab/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabGetSingleByPISignOffIdAndRepeat implements IReturn<UnscheduledTrastuzumabSingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<UnscheduledTrastuzumabGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/pi-signoff/form/unscheduled-trastuzumab/single/update", "POST")
// @Api(Description="Form - UnscheduledTrastuzumab")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UnscheduledTrastuzumabSingleResponse)", StatusCode=500)
// @DataContract
export class UnscheduledTrastuzumabPostUpdateById implements IReturn<UnscheduledTrastuzumabSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UnscheduledTrastuzumabPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnscheduledTrastuzumabPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnscheduledTrastuzumabSingleResponse(); }
}

// @Route("/pi-signoff/form/participant-study-summary/single/id/{Id}", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetSingleById implements IReturn<ParticipantStudySummarySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

// @Route("/pi-signoff/form/participant-study-summary/collection", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetCollection implements IReturn<ParticipantStudySummaryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummaryCollectionResponse(); }
}

// @Route("/pi-signoff/form/participant-study-summary/create", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostCreate implements IReturn<ParticipantStudySummarySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

// @Route("/pi-signoff/form/participant-study-summary/save", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostSave implements IReturn<ParticipantStudySummarySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

// @Route("/pi-signoff/form/participant-study-summary/validate", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummaryValidationResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostValidate implements IReturn<ParticipantStudySummaryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ParticipantStudySummary;

    public constructor(init?: Partial<ParticipantStudySummaryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParticipantStudySummaryValidationResponse(); }
}

// @Route("/pi-signoff/form/participant-study-summary/single/medical-review-id-repeat/{PISignOffId}/{Repeat}", "GET")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat implements IReturn<ParticipantStudySummarySingleResponse>, IFormGetSingleByPISignOffIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryGetSingleByPISignOffIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

// @Route("/pi-signoff/form/participant-study-summary/single/update", "POST")
// @Api(Description="Form - ParticipantStudySummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ParticipantStudySummarySingleResponse)", StatusCode=500)
// @DataContract
export class ParticipantStudySummaryPostUpdateById implements IReturn<ParticipantStudySummarySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ParticipantStudySummaryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParticipantStudySummaryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParticipantStudySummarySingleResponse(); }
}

// @Route("/pi-signoff/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/pi-signoff/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

// @Route("/pi-signoff/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/pi-signoff/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/pi-signoff/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupValidationResponse(); }
}

// @Route("/pi-signoff/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/pi-signoff/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
}

// @Route("/pi-signoff/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/pi-signoff/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/pi-signoff/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
}

// @Route("/pi-signoff/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/pi-signoff/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/pi-signoff/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/pi-signoff/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/pi-signoff/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountryValidationResponse(); }
}

// @Route("/pi-signoff/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/pi-signoff/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/pi-signoff/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/pi-signoff/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/pi-signoff/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionValidationResponse(); }
}

// @Route("/pi-signoff/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/pi-signoff/patient/collection", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/pi-signoff/patient/create", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/pi-signoff/patient/save", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/pi-signoff/patient/validate", "POST")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOffPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/pi-signoff/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - PISignOffPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/pi-signoff/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/pi-signoff/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: PISignOffRequestContext;
    public permissions: PISignOffPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-definition/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleById implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-definition/collection", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetCollection implements IReturn<PISignOffDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionCollectionResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-definition/single/code/{Code}", "GET")
// @Api(Description="PI Sign-off Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffDefinition.PISignOffDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffDefinitionGetSingleByCode implements IReturn<PISignOffDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<PISignOffDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffDefinitionSingleResponse(); }
}

// @Route("/pi-signoff/investigator/single/id/{Id}", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetSingleById implements IReturn<InvestigatorSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InvestigatorGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

// @Route("/pi-signoff/investigator/collection", "GET")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorCollectionResponse)", StatusCode=500)
// @DataContract
export class InvestigatorGetCollection implements IReturn<InvestigatorCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InvestigatorGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorCollectionResponse(); }
}

// @Route("/pi-signoff/investigator/create", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostCreate implements IReturn<InvestigatorSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

// @Route("/pi-signoff/investigator/save", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorSingleResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostSave implements IReturn<InvestigatorSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigatorSingleResponse(); }
}

// @Route("/pi-signoff/investigator/validate", "POST")
// @Api(Description="Investigator")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Investigator.InvestigatorValidationResponse)", StatusCode=500)
// @DataContract
export class InvestigatorPostValidate implements IReturn<InvestigatorValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Investigator;

    public constructor(init?: Partial<InvestigatorPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigatorPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigatorValidationResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/single/id/{Id}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleById implements IReturn<PISignOffSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/collection", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollection implements IReturn<PISignOffCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffCollectionResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/create", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostCreate implements IReturn<PISignOffSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/save", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostSave implements IReturn<PISignOffSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/validate", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostValidate implements IReturn<PISignOffValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOff;

    public constructor(init?: Partial<PISignOffPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffValidationResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/collection/pi-signoff-batch-id/{PISignOffBatchId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetCollectionByPISignOffBatchId implements IReturn<PISignOffCollectionResponse>, IPISignOffGetCollectionByPISignOffBatchId
{
    // @DataMember(Order=1)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetCollectionByPISignOffBatchId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetCollectionByPISignOffBatchId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffCollectionResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/single/pi-signoff-batch-id-patient-id/{PISignOffBatchId}/{PatientId}", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffGetSingleByPISignOffBatchIdAndPatientId implements IReturn<PISignOffSingleResponse>, IPISignOffGetSingleByPISignOffBatchIdAndPatientId
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public piSignOffBatchId?: number;

    public constructor(init?: Partial<PISignOffGetSingleByPISignOffBatchIdAndPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffGetSingleByPISignOffBatchIdAndPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/update-signoff", "POST")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostUpdateSignOff implements IReturn<PISignOffSingleResponse>, IPISignOffPostUpdateSignOff
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public piSignOffId?: number;

    // @DataMember(Order=2)
    public options: PISignOffUpdateOptions;

    public constructor(init?: Partial<PISignOffPostUpdateSignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostUpdateSignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off/collection/validate-forms", "GET")
// @Api(Description="PI Sign-off")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOff.PISignOffValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffPostFormValidationCollectionById implements IReturn<PISignOffValidationCollectionResponse>, IPISignOffPostFormValidationCollectionById
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffValidationCollectionResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-batch/single/id/{Id}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleById implements IReturn<PISignOffBatchSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-batch/collection", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCollectionResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetCollection implements IReturn<PISignOffBatchCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PISignOffBatchGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchCollectionResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-batch/create", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreate implements IReturn<PISignOffBatchSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-batch/save", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostSave implements IReturn<PISignOffBatchSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-batch/validate", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchValidationResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostValidate implements IReturn<PISignOffBatchValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOffBatch;

    public constructor(init?: Partial<PISignOffBatchPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchValidationResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-batch/create-signoff", "POST")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchCreationSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchPostCreateSignOff implements IReturn<PISignOffBatchCreationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: PISignOffBatch;

    // @DataMember(Order=2)
    public options: PISignOffBatchCreationOptions;

    public constructor(init?: Partial<PISignOffBatchPostCreateSignOff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchPostCreateSignOff'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PISignOffBatchCreationSingleResponse(); }
}

// @Route("/pi-signoff/pi-sign-off-batch/single/pi-signoff-batch-id-patient-id/{PISignOffDefinitionId}/{Repeat}", "GET")
// @Api(Description="PI Sign-Off Batch")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.PISignOffBatch.PISignOffBatchSingleResponse)", StatusCode=500)
// @DataContract
export class PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat implements IReturn<PISignOffBatchSingleResponse>, IPISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat
{
    // @DataMember(Order=1)
    public piSignOffDefinitionId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PISignOffBatchGetSingleByPISignOffDefinitionIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PISignOffBatchSingleResponse(); }
}

// @Route("/pi-signoff/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/pi-signoff/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/pi-signoff/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/pi-signoff/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/pi-signoff/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

// @Route("/pi-signoff/query/collection/pi-signoff-id/{PISignOffId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByPISignOffId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByPISignOffId
{
    // @DataMember(Order=1)
    public piSignOffId?: number;

    public constructor(init?: Partial<QueryGetCollectionByPISignOffId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByPISignOffId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/pi-signoff/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/pi-signoff/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/pi-signoff/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

// @Route("/pi-signoff/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/pi-signoff/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/pi-signoff/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

// @Route("/pi-signoff/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__PISignOff.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

