/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import { IInputRenderProps, useScopedField } from '@ngt/forms-core';
import { Typography } from '@mui/material';
import { IValidationError } from '@ngt/forms';
import { ReactNode } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type SymbolFieldProps = IInputRenderProps<string, IValidationError>

export interface ISymbolDisplayProps extends SymbolFieldProps {
    symbolName: string;
    suffix?: ReactNode;
    prefix?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const symbolSubscription = { value: true };

const SymbolDisplay: React.FunctionComponent<ISymbolDisplayProps> = ({
    inputRender: { state: { value, ...restInputState }, actions: { ...restInputActions } },
    symbolName,
    prefix,
    suffix,
    ...rest
}) => {
    const { state: { value: symbol } } = useScopedField<string, IValidationError>(symbolName, symbolSubscription, false)
    return (
        <Typography>
            {prefix}{symbol} {value}{value != null ? suffix : null}
        </Typography>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SymbolDisplay;
