import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField, GroupedField } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { ParticipantStudySummary as DeathDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from '@mui/styles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IDeathProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    },
    centerText: {
        textAlign: 'center'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasNoDeathForm: ICondition<DeathDto> = ({ formState: { value } = {} }) => !value?.eventCrfId;
const hasDeathForm: ICondition<DeathDto> = ({ formState: { value } = {} }) => !!value?.eventCrfId;

const isDeathCauseOther: ICondition<DeathDto> = ({ formState: { value } = {} }) => value?.causeOfDeath?.toLowerCase() === 'other';

const conditionSubscription = { value: true };

const Death: FunctionComponent<IDeathProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Death
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                <GroupedFields>
                <Condition
                    type={DeathDto}
                    condition={hasDeathForm}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="dateOfDeath"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedFields>
                        <GroupedField
                            name="causeOfDeath"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={DeathDto}
                            condition={isDeathCauseOther}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="causeOfDeathSpec"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                    </GroupedFields>
                </Condition>
                <Condition
                    type={DeathDto}
                    condition={hasNoDeathForm}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Death Report Form"
                            description="There is no death report form associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>
                </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default Death;
