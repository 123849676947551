import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, IValidationError } from '@ngt/forms';
import { ParticipantStudySummary as AdverseEventsDto, AdverseEvent, DurvalumabCycleDates } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, useScopedField } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from '@mui/styles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OpmsSaeDisplay from '../../components/form/input/display/OpmsSaeDisplay';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;

interface IAdverseEventsProps {
}
const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        //margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    },
    gridSize: {
        fontSize: `1rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `1rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',

            '& .MuiGrid-root': {
                overflowX: 'auto',
                width: '100%'
            }
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const suspectDrugAccessor = () => "None";

const adverseEventColumns: IGroupedSubformGridCell<AdverseEvent>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row: any) => row?.event,
        minWidth: 110
    },
    {
        name: 'systemsOrganClass',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row : any) => row?.systemsOrganClass
    },
    {
        name: 'aeTerm',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="aeTermSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'aeTermSpecify'
                }
            ]
        },
        sortValue: (row : any) => !!row?.aeTerm ? `${row?.aeTerm} - ${row?.aeTermSpecify}` : undefined
    },
    {
        name: 'grade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row : any) => row?.grade
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row : any) => row?.onset
    },
    {
        name: 'outcome',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row : any) => row?.outcome
    },
    {
        name: 'resolutionDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row : any) => row?.resolutionDate
    },
    {
        name: 'aeRelationship',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row : any) => row?.aeRelationship
    },
    {
        name: 'actionTaken',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row : any) => row?.actionTaken
    },
    {
        name: 'qualifyAsAnAesi',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row : any) => row?.qualifyAsAnAesi
    },
    {
        name: 'meetSaeCriteria',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row : any) => row?.meetSaeCriteria
    }
];

const hasNoCycleDates: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.cycleDates || value?.cycleDates?.length === 0;
const hasCycleDates: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.cycleDates && value?.cycleDates?.length > 0;

const hasNoAes: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.adverseEvents || value?.adverseEvents?.length === 0;
const hasAes: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.adverseEvents && value?.adverseEvents?.length > 0;

const conditionSubscription = { value: true };

const CycleDates = () => {
    const { state: { value: cycleDates } } = useScopedField<DurvalumabCycleDates[], IValidationError>('cycleDates', conditionSubscription, false);

    const theme = useTheme();

    if (!cycleDates) {
        return <></>;
    }

    return (
        <Grid
            container
            spacing={1}
            sx={{
                width: '100% !important',
                padding: theme.spacing(2),

            }}
        >
            {
                cycleDates?.map((cycleDate, i) => {
                    return (
                        <Grid
                            item
                            sx={{
                                minWidth: '220px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid black',
                                    padding: theme.spacing(4, 2),
                                    width: '100%'
                                }}
                            >
                                <Typography style={{ marginRight: theme.spacing(1), fontWeight: 500 }}>
                                    {cycleDate.eventName}
                                </Typography>
                                <Typography>
                                    {
                                        cycleDate.administered === false ?
                                            'Not Administered'
                                            :
                                            cycleDate.date ? DateTime.fromISO(cycleDate.date).toFormat('dd/MM/yyyy') : ''
                                    }
                                </Typography>
                            </div>
                        </Grid>
                    );
                })
            }
        </Grid>
    );

}

const AdverseEvents: FunctionComponent<IAdverseEventsProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Adverse Events
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Cycle Dates
                    </Typography>
                    
                    <Typography className={classes.subtitle}>
                        (dates of administration of durvalumab)
                    </Typography>

                    <Condition
                        type={AdverseEventsDto}
                        condition={hasCycleDates}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <CycleDates />
                    </Condition>
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasNoCycleDates}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Cycle Dates"
                                description="There is no cycle date associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Adverse Events
                    </Typography>

                    <Condition
                        type={AdverseEventsDto}
                        condition={hasAes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                type={AdverseEvent}
                                name="adverseEvents"
                                columns={adverseEventColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasNoAes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Adverse Events"
                                description="There is no adverse event associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default AdverseEvents;
