import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField, GroupedField } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, FieldProvider, FormLabel } from '@ngt/forms';
import { ParticipantStudySummary as BiopsyReportsDto, PISignOffPatient } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert, PatientContext } from '@ngt/forms-trials';
import { makeStyles } from '@mui/styles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import ValueWithSpecificationDisplay from '../../components/form/input/display/ValueWithSpecificationDisplay';
import { DateTime } from 'luxon';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SymbolDisplay from '../../components/form/input/display/SymbolDisplay';

interface IBiopsyReportsProps {
}


const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    },
    greyRow: {
        '& td': {
            backgroundColor: theme.palette.grey[200]
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const isCycle1Biopsy1Collected: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy1NotDone === false;
const isCycle1Biopsy2Collected: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy2NotDone === false;
const isProgressionBiopsy1Collected: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy1NotDone === false;
const isProgressionBiopsy2Collected: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy2NotDone === false;

const isCycle1Biopsy1CommentNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy1Comments?.length ?? 0) > 0 || value?.cycle1Biopsy1NotDone === true;
const isCycle1Biopsy1CommentMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy1Comments?.length ?? 0) === 0 && value?.cycle1Biopsy1NotDone === false;
const isCycle1Biopsy2CommentNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy2Comments?.length ?? 0) > 0 || value?.cycle1Biopsy2NotDone === true;
const isCycle1Biopsy2CommentMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy2Comments?.length ?? 0) === 0 && value?.cycle1Biopsy2NotDone === false;
const isProgressionBiopsy1CommentNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy1Comments?.length ?? 0) > 0 || value?.progressionBiopsy1NotDone === true;
const isProgressionBiopsy1CommentMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy1Comments?.length ?? 0) === 0 && value?.progressionBiopsy1NotDone === false;
const isProgressionBiopsy2CommentNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy2Comments?.length ?? 0) > 0 || value?.progressionBiopsy2NotDone === true;
const isProgressionBiopsy2CommentMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy2Comments?.length ?? 0) === 0 && value?.progressionBiopsy2NotDone === false;

const isCycle1Biopsy1OestrogenNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy1OestrogenNotDone === true;
const isCycle1Biopsy1OestrogenDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy1OestrogenNotDone !== true;
const isCycle1Biopsy2OestrogenNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy2OestrogenNotDone === true;
const isCycle1Biopsy2OestrogenDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy2OestrogenNotDone !== true;
const isProgressionBiopsy1OestrogenNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy1OestrogenNotDone === true;
const isProgressionBiopsy1OestrogenDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy1OestrogenNotDone !== true;
const isProgressionBiopsy2OestrogenNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy2OestrogenNotDone === true;
const isProgressionBiopsy2OestrogenDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy2OestrogenNotDone !== true;
const isCycle1Biopsy1IshNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy1IshNotDone === true;
const isCycle1Biopsy1IshDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy1IshNotDone !== true;
const isCycle1Biopsy2IshNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy2IshNotDone === true;
const isCycle1Biopsy2IshDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.cycle1Biopsy2IshNotDone !== true;
const isProgressionBiopsy1IshNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy1IshNotDone === true;
const isProgressionBiopsy1IshDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy1IshNotDone !== true;
const isProgressionBiopsy2IshNotDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy2IshNotDone === true;
const isProgressionBiopsy2IshDone: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => value?.progressionBiopsy2IshNotDone !== true;
const isCycle1Biopsy1TilAssessedNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy1TilAssessed?.length ?? 0) > 0 || value?.cycle1Biopsy1NotDone === true;
const isCycle1Biopsy1TilAssessedMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy1TilAssessed?.length ?? 0) === 0 && value?.cycle1Biopsy1NotDone === false;
const isCycle1Biopsy2TilAssessedNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy2TilAssessed?.length ?? 0) > 0 || value?.cycle1Biopsy2NotDone === true;
const isCycle1Biopsy2TilAssessedMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.cycle1Biopsy2TilAssessed?.length ?? 0) === 0 && value?.cycle1Biopsy2NotDone === false;
const isProgressionBiopsy1TilAssessedNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy1TilAssessed?.length ?? 0) > 0 || value?.progressionBiopsy1NotDone === true;
const isProgressionBiopsy1TilAssessedMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy1TilAssessed?.length ?? 0) === 0 && value?.progressionBiopsy1NotDone === false;
const isProgressionBiopsy2TilAssessedNotMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy2TilAssessed?.length ?? 0) > 0 || value?.progressionBiopsy2NotDone === true;
const isProgressionBiopsy2TilAssessedMissing: ICondition<BiopsyReportsDto> = ({ formState: { value } = {} }) => (value?.progressionBiopsy2TilAssessed?.length ?? 0) === 0 && value?.progressionBiopsy2NotDone === false;

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;

const BiopsyReports: FunctionComponent<IBiopsyReportsProps> = () => {
    const classes = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2)
        },
        subtitle: {
            padding: theme.spacing(2, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        },
        greyRow: {
            '& td': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        noBorderRow: {
            border: `${0} !important`,

            '& > *': {
                border: `${0} !important`
            }
        }
    }), [theme]);

    const { data } = useContext(PatientContext);

    const patient = data as PISignOffPatient;

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Biopsy Reports
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                <GroupedFields>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        width: '300px'
                                    }}
                                >
                                </TableCell>
                                <TableCell colSpan={2} style={{ paddingRight: '10rem' }}>
                                    <Typography variant='h3' style={{ textAlign: 'center' }}>
                                        {patient.treatmentArm?.includes('ER') ? 'Cycle 1' : 'Cycle 2'}
                                    </Typography>
                                </TableCell>
                                <TableCell colSpan={2} style={{ paddingRight: '10rem' }}>
                                    <Typography variant='h3' style={{ textAlign: 'center' }}>
                                        Progression
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        width: '20%'
                                    }}
                                >
                                </TableCell>
                                <TableCell
                                    sx={{
                                        width: '20%'
                                    }}
                                >
                                    <Typography variant='h3'>
                                        Biopsy 1
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        width: '20%'
                                    }}
                                >
                                    <Typography variant='h3'>
                                        Biopsy 2
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        width: '20%'
                                    }}
                                >
                                    <Typography variant='h3'>
                                        Biopsy 1
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        width: '20%'
                                    }}
                                >
                                    <Typography variant='h3'>
                                        Biopsy 2
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ ...styles.noBorderRow  }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1Date">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy1NotDone"
                                        component={IfElseDisplay}
                                        condition={(value: boolean) => value === true ? true : false}
                                        ifTableCell
                                        ifName="cycle1Biopsy1NotDone"
                                        ifAccessor={(value: number) => "Not Done"}
                                        elseTableCell
                                        elseName="cycle1Biopsy1Date"
                                        elseAccessor={dateAccessor}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy2NotDone"
                                        component={IfElseDisplay}
                                        condition={(value: boolean) => value === true ? true : false}
                                        ifTableCell
                                        ifName="cycle1Biopsy2NotDone"
                                        ifAccessor={(value: number) => "Not Done"}
                                        elseTableCell
                                        elseName="cycle1Biopsy2Date"
                                        elseAccessor={dateAccessor}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy1NotDone"
                                        component={IfElseDisplay}
                                        condition={(value: boolean) => value === true ? true : false}
                                        ifTableCell
                                        ifName="progressionBiopsy1NotDone"
                                        ifAccessor={(value: number) => "Not Done"}
                                        elseTableCell
                                        elseName="progressionBiopsy1Date"
                                        elseAccessor={dateAccessor}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy2NotDone"
                                        component={IfElseDisplay}
                                        condition={(value: boolean) => value === true ? true : false}
                                        ifTableCell
                                        ifName="progressionBiopsy2NotDone"
                                        ifAccessor={(value: number) => "Not Done"}
                                        elseTableCell
                                        elseName="progressionBiopsy2Date"
                                        elseAccessor={dateAccessor}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1Site">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy1Site"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="cycle1Biopsy1SiteSpec"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy2Site"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="cycle1Biopsy1SiteSpec"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy1Site"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="cycle1Biopsy1SiteSpec"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy1Site"
                                        component={ValueWithSpecificationDisplay}
                                        specificationName="cycle1Biopsy1SiteSpec"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1Comments">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1CommentNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1Comments"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1CommentMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1Comments"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "No Comment"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2CommentNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2Comments"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2CommentMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2Comments"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "No Comment"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1CommentNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1Comments"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1CommentMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1Comments"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "No Comment"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2CommentNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2Comments"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2CommentMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2Comments"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "No Comment"}
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow, ...styles.greyRow }}>
                                <TableCell colSpan={5}>
                                    <Typography variant='h4'>
                                        Receptor Staining
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow, ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1OestrogenPercentage">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1OestrogenDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1OestrogenPercentage"
                                            component={SymbolDisplay}
                                            symbolName="cycle1Biopsy1OestrogenSign"
                                            suffix="%"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1OestrogenNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1OestrogenNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="cycle1Biopsy1OestrogenReason"
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2OestrogenDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2OestrogenPercentage"
                                            component={SymbolDisplay}
                                            symbolName="cycle1Biopsy2OestrogenSign"
                                            suffix="%"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2OestrogenNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2OestrogenNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="cycle1Biopsy2OestrogenReason"
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1OestrogenDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1OestrogenPercentage"
                                            component={SymbolDisplay}
                                            symbolName="progressionBiopsy1OestrogenSign"
                                            suffix="%"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1OestrogenNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1OestrogenNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="progressionBiopsy1OestrogenReason"
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2OestrogenDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2OestrogenPercentage"
                                            component={SymbolDisplay}
                                            symbolName="progressionBiopsy2OestrogenSign"
                                            suffix="%"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2OestrogenNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2OestrogenNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="progressionBiopsy2OestrogenReason"
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow, ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1Ihc">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1Collected}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1Ihc"
                                            component={IfElseDisplay}
                                            condition={(value: string) => value?.length > 0}
                                            ifTableCell
                                            ifName="cycle1Biopsy1Ihc"
                                            elseTableCell
                                            elseName="cycle1Biopsy1Ihc"
                                            elseAccessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2Collected}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2Ihc"
                                            component={IfElseDisplay}
                                            condition={(value: string) => value?.length > 0}
                                            ifTableCell
                                            ifName="cycle1Biopsy2Ihc"
                                            elseTableCell
                                            elseName="cycle1Biopsy2Ihc"
                                            elseAccessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1Collected}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1Ihc"
                                            component={IfElseDisplay}
                                            condition={(value: string) => value?.length > 0}
                                            ifTableCell
                                            ifName="progressionBiopsy1Ihc"
                                            elseTableCell
                                            elseName="progressionBiopsy1Ihc"
                                            elseAccessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2Collected}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2Ihc"
                                            component={IfElseDisplay}
                                            condition={(value: string) => value?.length > 0}
                                            ifTableCell
                                            ifName="progressionBiopsy2Ihc"
                                            elseTableCell
                                            elseName="progressionBiopsy2Ihc"
                                            elseAccessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1Ish">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1IshDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1Ish"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1IshNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1IshNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="cycle1Biopsy1IshReason"
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2IshDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2Ish"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2IshNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2IshNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="cycle1Biopsy2IshReason"
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1IshDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1Ish"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1IshNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1IshNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="progressionBiopsy1IshReason"
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2IshDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2Ish"
                                            component={TextDisplay}
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2IshNotDone}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2IshNotDone"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: boolean) => "Not Done"}
                                            specificationName="progressionBiopsy2IshReason"
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell colSpan={5}>
                                    <Typography variant='h4'>
                                        ERBB2 Amplification
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1Erbb2">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy1Erbb2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy2Erbb2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy1Erbb2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy2Erbb2"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1MeanGene">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy1MeanGene"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy2MeanGene"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy1MeanGene"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy2MeanGene"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow, ...styles.greyRow }}>
                                <TableCell colSpan={5}>
                                    <Typography variant='h4'>
                                        Tumour Infiltrating Lymphocytes
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow, ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1TilAssessed">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1TilAssessedNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            specificationName="cycle1Biopsy1TilReason"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy1TilAssessedMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy1TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2TilAssessedNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            specificationName="cycle1Biopsy2TilReason"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isCycle1Biopsy2TilAssessedMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="cycle1Biopsy2TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1TilAssessedNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            specificationName="progressionBiopsy1TilReason"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy1TilAssessedMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy1TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                                <TableCell>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2TilAssessedNotMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            specificationName="progressionBiopsy2TilReason"
                                        />
                                    </Condition>
                                    <Condition
                                        type={BiopsyReportsDto}
                                        condition={isProgressionBiopsy2TilAssessedMissing}
                                        subscription={conditionSubscription}
                                        mode={ConditionMode.Show}
                                    >
                                        <InputOnlyField
                                            name="progressionBiopsy2TilAssessed"
                                            component={ValueWithReasonAndSpecificationDisplay}
                                            accessor={(value: string) => "Missing"}
                                        />
                                    </Condition>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.greyRow }}>
                                <TableCell>
                                    <FieldProvider name="cycle1Biopsy1TilPercentage">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy1TilPercentage"
                                        component={SymbolDisplay}
                                        symbolName="cycle1Biopsy1TilSign"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="cycle1Biopsy2TilPercentage"
                                        component={SymbolDisplay}
                                        symbolName="cycle1Biopsy2TilSign"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy1TilPercentage"
                                        component={SymbolDisplay}
                                        symbolName="progressionBiopsy1TilSign"
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="progressionBiopsy2TilPercentage"
                                        component={SymbolDisplay}
                                        symbolName="progressionBiopsy2TilSign"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default BiopsyReports;
