import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { ConcomitantMedicationBaseline, ConcomitantMedicationOnStudy, ParticipantStudySummary as FollowUpDto, SurvivalFollowUp } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from '@mui/styles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';;

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IFollowUpProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    accordionSubtitle: {
        color: 'white'
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const survivalFollowUpColumns: IGroupedSubformGridCell<SurvivalFollowUp>[] = [
    {
        name: 'timepoint',
        content: (
            <Input
            component={OpenClinicaFormDisplay}
            openClinicaUrl={openClinicaUrl}
            eventCrfIdName="eventCrfId"
            studyEventIdName="studyEventId"
            btnMinWidth="140px"
            eventCrfIdPrependParentName
            eventCrfIdTableCell
            studyEventIdPrependParentName
            studyEventIdTableCell
            textValueButton
            />
        ),
        minWidth: 110
    },
    {
        name: 'dateOfContact',
        content: (
            <Input component={DateDisplay} />
        ),
    },
    {
        name: 'followUpBy',
        content: (
            <Input component={TextDisplay} />
        ),
    },
    {
        name: 'anyChanges',
        content: (
            <Input component={TextDisplay} />
        ),
    },
    {
        name: 'trastuzumab',
        content: (
            <Input component={TextDisplay} />
        ),
    },
    {
        name: 'endocrine',
        content: (
            <Input component={TextDisplay} />
        ),
    }
];

const hasNoSurvivalFollowUp: ICondition<FollowUpDto> = ({ formState: { value } = {} }) => !value?.survivalFollowUps || value?.survivalFollowUps?.length === 0;;
const hasSurvivalFollowUp: ICondition<FollowUpDto> = ({ formState: { value } = {} }) => !!value?.survivalFollowUps && value?.survivalFollowUps?.length > 0;


const conditionSubscription = { value: true };

const FollowUp: FunctionComponent<IFollowUpProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        Survival Follow up
                    </Typography>
                </AccordionSummary>

                
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                    <GroupedFields>
                        <Condition
                            type={FollowUpDto}
                            condition={hasSurvivalFollowUp}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={SurvivalFollowUp}
                                name="survivalFollowUps"
                                columns={survivalFollowUpColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={FollowUpDto}
                            condition={hasNoSurvivalFollowUp}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <Box className={classes.alert}>
                                <BasicAlert
                                    title="No Survival Follow Up Forms"
                                    description="There is no survival follow up forms associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </Box>
                        </Condition>
                    </GroupedFields>
                </AccordionDetails>


            </Accordion>
        </>
    );
};

export default FollowUp;
