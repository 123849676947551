import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPISignOffForm, GroupedSubformGrid, ICondition, IGroupedSubformGridCell, OpenClinicaFormDisplay, Condition, InputOnlyField, GroupedField } from '@ngt/forms-pi-signoff';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay, FieldProvider, FormLabel } from '@ngt/forms';
import { ParticipantStudySummary as TreatmentDto, TremelimumabTreatment, DurvalumabTreatment, TrastuzumabTreatment, UnscheduledTrastuzumab, AromataseTreatment, GoserelinTreatment } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from '@mui/styles';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';

interface ITreatmentProps {
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    legend: {
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
        '& span': {
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap'
        }
    },
    heading: {
        padding: theme.spacing(2, 0, 1),
    },
    subheading: {
        padding: theme.spacing(2, 0),
    },
    accordionSubtitle: {
        color: 'white'
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 2),
        color: 'white'
    },
    accordionDetails: {
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            padding: theme.spacing(0, 2),
            margin: theme.spacing(0, 2)
        }
    },
    centerText: {
        textAlign: 'center'
    }
}));


const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const isErPositiveNegativeCohort: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => value?.treatmentCohort?.includes('ER') ?? false;
const isSingleDoseCohort: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => value?.treatmentCohort?.includes('Single Dose') ?? false;
const hasTremelimumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.tremelimumabCycles?.length ?? 0) > 0;
const hasNoTremelimumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.tremelimumabCycles?.length ?? 0) === 0;
const hasDurvalumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.durvalumabCycles?.length ?? 0) > 0;
const hasNoDurvalumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.durvalumabCycles?.length ?? 0) === 0;
const hasTrastuzumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.trastuzumabCycles?.length ?? 0) > 0;
const hasNoTrastuzumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.trastuzumabCycles?.length ?? 0) === 0;
const hasUnscheduledTrastuzumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.unscheduledTrastuzumabs?.length ?? 0) > 0;
const hasNoUnscheduledTrastuzumab: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.unscheduledTrastuzumabs?.length ?? 0) === 0;
const hasAromatase: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.aromataseTreatments?.length ?? 0) > 0;
const isAromataseNotApplicable: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => !!value?.aromataseNotApplicable;
const hasNoAromatase: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.aromataseTreatments?.length ?? 0) === 0 && !value?.aromataseNotApplicable;
const hasGoserelin: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.goserelinTreatments?.length ?? 0) > 0;
const isGoserelinNotApplicable: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => !!value?.goserelinNotApplicable;
const hasNoGoserelin: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.goserelinTreatments?.length ?? 0) === 0 && !value?.goserelinNotApplicable;


const conditionSubscription = { value: true };

const tremelimumabColumns: IGroupedSubformGridCell<TremelimumabTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName,
        minWidth: 100
    },
    {
        name: 'administered',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                reasonName="administeredSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        width: 210,
        sortValue: row => row?.administered
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.date
    },
    {
        name: 'doseAdministered',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.doseAdministered
    },
    {
        name: 'expectedDose',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.expectedDose
    },
    {
        name: 'doseDelayed',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                    reasonName="doseDelayedReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="doseDelayedSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.doseDelayed
    },
    {
        name: 'lastDose',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                    reasonName="lastDoseSpec"
                    reasonPrependParentName
                    reasonTableCell
                />
            </>
        ),
        sortValue: row => row?.lastDose
    }
];

const durvalumabColumns: IGroupedSubformGridCell<DurvalumabTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName,
        minWidth: 100
    },
    {
        name: 'administered',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                reasonName="administeredSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        width: 210,
        sortValue: row => row?.administered
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.date
    },
    {
        name: 'doseAdministered',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.doseAdministered
    },
    {
        name: 'expectedDose',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.expectedDose
    },
    {
        name: 'doseDelayed',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                    reasonName="doseDelayedReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="doseDelayedSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.doseDelayed
    },
    {
        name: 'lastDose',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                    reasonName="lastDoseSpec"
                    reasonPrependParentName
                    reasonTableCell
                />
            </>
        ),
        sortValue: row => row?.lastDose
    }
];

const trastuzumabColumns: IGroupedSubformGridCell<TrastuzumabTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName,
        minWidth: 100
    },
    {
        name: 'administered',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                reasonName="administeredSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        width: 210,
        sortValue: row => row?.administered
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.date
    },
    {
        name: 'doseAdministered',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.doseAdministered
    },
    {
        name: 'weight',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> kg</Typography>
                }
            />
        ),
        sortValue: row => row?.weight
    },
    {
        name: 'intendedDose',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg/kg</Typography>
                }
            />
        ),
        sortValue: row => row?.intendedDose
    },
    {
        name: 'doseDelayed',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                    reasonName="doseDelayedReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="doseDelayedSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.doseDelayed
    },
    {
        name: 'lastDose',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                    reasonName="lastDoseSpec"
                    reasonPrependParentName
                    reasonTableCell
                />
            </>
        ),
        sortValue: row => row?.lastDose
    }
];

const unscheduledTrastuzumabColumns: IGroupedSubformGridCell<UnscheduledTrastuzumab>[] = [
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.date
    },
    {
        name: 'doseAdministered',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.doseAdministered
    },
    {
        name: 'intendedDose',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg/kg</Typography>
                }
            />
        ),
        sortValue: row => row?.intendedDose
    },
    {
        name: 'doseDelayed',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
                    reasonName="doseDelayedReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="doseDelayedSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.doseDelayed
    }
];

const aromataseColumns: IGroupedSubformGridCell<AromataseTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName,
        minWidth: 100
    },
    {
        name: 'name',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="nameSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: row => row?.name
    },
    {
        name: 'dailyDose',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.dailyDose
    },
    {
        name: 'startDate',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.startDate
    },
    {
        name: 'stopDate',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.stopDate
    },
    {
        name: 'reason',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="reasonSpec"
                    reasonPrependParentName
                    reasonTableCell
                />
            </>
        ),
        sortValue: row => row?.reason
    }
];

const goserelinColumns: IGroupedSubformGridCell<GoserelinTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName,
        minWidth: 100
    },
    {
        name: 'administered',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                accessor={(value: boolean) => value != null ? (value ? "Yes" : "No") : ""}
            />
        ),
        sortValue: row => row?.administered
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.date
    },
    {
        name: 'injectionWithin',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="injectionWithinReason"
                reasonPrependParentName
                reasonTableCell
                specificationName="injectionWithinSpec"
                specificationPrependParentName
                specificationTableCell
            />
        ),
        sortValue: row => row?.injectionWithin
    },
    {
        name: 'lastAdministration',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="lastAdministrationReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="lastAdministrationSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.lastAdministration
    }
];

const Treatment: FunctionComponent<ITreatmentProps> = () => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded
                elevation={0}
                classes={{
                    root: classes.accordion
                }}
            >
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                    className={classes.accordionSummary}
                >
                    <Typography variant="h2" className={classes.accordionSubtitle}>
                        DIAmOND Treatment
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                <Condition
                    type={TreatmentDto}
                    condition={isErPositiveNegativeCohort}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Treatment Exposure &ndash; ER+ / ER-
                        </Typography>

                        <GroupedField
                            name="treatmentCohort"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="plannedStartDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesTremelimumabIp">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesDurvalumabIp">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesTrastuzumabIp">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><Typography>Induction Phase</Typography></TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesTremelimumabIp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesDurvalumabIp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesTrastuzumabIp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography>Treatment Phase</Typography></TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesTremelimumabTp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesDurvalumabTp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesTrastuzumabTp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GroupedFields>
                </Condition>
                <Condition
                    type={TreatmentDto}
                    condition={isSingleDoseCohort}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Treatment Exposure &ndash; Single Dose
                        </Typography>

                        <GroupedField
                            name="treatmentCohort"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="plannedStartDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <FieldProvider name="dosesTremelimumabSingleDose">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesDurvalumabSingleDose">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesTrastuzumabSingleDose">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesTremelimumabSingleDose"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.centerText}>
                                        <InputOnlyField
                                            name="dosesDurvalumabSingleDose"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.centerText}>
                                        <InputOnlyField
                                            name="dosesTrastuzumabSingleDose"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GroupedFields>
                </Condition>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Tremelimumab
                    </Typography>

                    <Condition
                        type={TreatmentDto}
                        condition={hasTremelimumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={TremelimumabTreatment}
                            name="tremelimumabCycles"
                            columns={tremelimumabColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={hasNoTremelimumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Tremelimumab Treatments"
                                description="There is no Tremelimumab treatment associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Durvalumab
                    </Typography>

                    <Condition
                        type={TreatmentDto}
                        condition={hasDurvalumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={DurvalumabTreatment}
                            name="durvalumabCycles"
                            columns={durvalumabColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={hasNoDurvalumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Durvalumab Treatments"
                                description="There is no Durvalumab treatment associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Trastuzumab
                    </Typography>

                    <Condition
                        type={TreatmentDto}
                        condition={hasTrastuzumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={TrastuzumabTreatment}
                            name="trastuzumabCycles"
                            columns={trastuzumabColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={hasNoTrastuzumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Trastuzumab Treatments"
                                description="There is no Trastuzumab treatment associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>   
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Unscheduled Trastuzumab
                    </Typography>

                    <Condition
                        type={TreatmentDto}
                        condition={hasUnscheduledTrastuzumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={TrastuzumabTreatment}
                            name="unscheduledTrastuzumabs"
                            columns={unscheduledTrastuzumabColumns}
                            disabled
                        />

                        <GroupedField
                            name="trastuzumabDiscontinued"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="trastuzumabDiscontinuedSpec"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={hasNoUnscheduledTrastuzumab}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Unscheduled Trastuzumab Treatments"
                                description="There is no unscheduled Trastuzumab treatment associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Aromatase Inhibitor Treatment
                    </Typography>

                    <Condition
                        type={TreatmentDto}
                        condition={hasAromatase}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={AromataseTreatment}
                            name="aromataseTreatments"
                            columns={aromataseColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={hasNoAromatase}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Aromatase Inhibitor Treatments"
                                description="There is no Aromatase Inhibitor treatment associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={isAromataseNotApplicable}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="Not Applicable"
                                description="The Aromatase Inhibitor treatment is not applicable to this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Goserelin Treatment
                    </Typography>

                    <Condition
                        type={TreatmentDto}
                        condition={hasGoserelin}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={GoserelinTreatment}
                            name="goserelinTreatments"
                            columns={goserelinColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={hasNoGoserelin}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Goserelin Treatments"
                                description="There is no Goserelin treatment associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={TreatmentDto}
                        condition={isGoserelinNotApplicable}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="Not Applicable"
                                description="The Goserelin treatment is not applicable to this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default Treatment;
